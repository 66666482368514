import { memo } from 'react';
import Select, { components } from 'react-select';
import { svgIcons } from '../svgIcons/svgIcons';

const ProjectSelect = (props) => {
  return (
    <Select
      {...props}
      onChange={(e) => {
        props.onChange &&
          props.onChange(props.projectList.find((item) => item.id === e.value));
      }}
      value={{
        value: props.project ? props.project.id : 'invalid project',
        label: props.project ? props.project.property.name : 'invalid project',
      }}
      options={props.projectList.map((item) => ({
        value: item.id,
        label: item.property.name,
      }))}
      placeholder="Select property"
      components={{
        IndicatorSeparator: () => null,
        Control: ({ children, ...props }) => (
          <components.Control {...props}>
            <div className="w-5 h-5 flex items-center justify-center">
              <div className="w-4">{svgIcons.property}</div>
            </div>
            {children}
          </components.Control>
        ),
      }}
      styles={{
        control: (styles, state, base, css) => ({
          ...base,
          ...styles,
          ...css,
          height: 46,
          paddingLeft: 12,
          boxShadow: state.menuIsOpen ? '0px 0px 0px 4px #687BFE40' : null,
          borderColor: state.menuIsOpen ? '#687bfe' : '#CED3DB',
          '&:hover': {
            cursor: 'pointer',
            borderColor: '#687bfe',
          },
        }),
        container: (base) => ({
          ...base,
          minWidth: 300,
        }),
        DropdownIndicator: (base) => ({
          ...base,
          padding: '0px',
          outerWidth: '10px',
        }),
        menu: (base) => ({
          ...base,
          borderRadius: 4,
          marginTop: 4,
        }),
        option: (base) => ({
          ...base,
          borderWidth: 0,
          borderRadius: 4,
          padding: '11px 12px',
        }),
        menuList: (style) => ({
          ...style,
          padding: 0,
          '::-webkit-scrollbar': {
            width: '5px',
            height: '100%',
          },
          '::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px #e6effb',
            borderRadius: '10px',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#b0c5de',
            borderRadius: '10px',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: '#687bfe',
          },
        }),
      }}
    />
  );
};

export default memo(ProjectSelect);
