import config from './config';
import cookies from './cookies';
import { delay } from './promises';
import uxQueue from './uxqueue';
import uxDialog from './uxdialog';

export const HTTP_STATUS = {
  OK: 200, //
  CREATED: 201,
  ACCEPTED: 202,
  NOCONTENT: 204,
  NOTMODIFIED: 304,
  UNAUTHORIZED: 401, // Unauthorized error.
  FORBIDDEN: 403, // Forbidden
  NOTFOUND: 404, //Not found
  INTERNALSERVER: 500,
};

HTTP_STATUS.isNotFailure = (code) => code >= 200 && code < 400;
HTTP_STATUS.isSuccess = (code) => code >= 200 && code < 300;
HTTP_STATUS.isFailure = (code) => code > 300;
HTTP_STATUS.isClientError = (code) => code >= 400 && code < 500;
HTTP_STATUS.isServerError = (code) => code >= 500;

// Exception Classes

/**
 * The Base class for all API Call Errors
 **/
export class APICallError {
  constructor(error) {
    this.error = error;
  }
}

export class APIAgentError extends APICallError {
  constructor(error, message) {
    super(error);
    this.message = message;
  }
}

export class CommunicationError extends APICallError {}

export class InternalServerError extends APICallError {}

export class ServerDeclineError extends APICallError {
  constructor(response, error) {
    super(error);
    this.response = response;
  }
}

export class AuthenticationError extends ServerDeclineError {}

export class AuthorizationError extends ServerDeclineError {}

export class BadRequestError extends ServerDeclineError {}
// TODO: More Error Cases 404 - Not Found, Wrong URL 405 - Method Not Allowed

export const retriableErrors = [
  CommunicationError,
  InternalServerError,
  ServerDeclineError,
  AuthorizationError,
  BadRequestError,
];
export const redirableErrors = [AuthenticationError];

export const RetryBehavior = {
  // bit flags inside:
  // 2^0:retry, 2^1:notify, 2^2: show confirm, 2^3:auto
  NoRetry: 0, // b00
  NoRetryAndNotify: 1, // b01
  OnConfirm: 5, // b101
  Auto: 9, // b1001
};
Object.freeze(RetryBehavior);

export const RedirBehavior = {
  // bit flags inside:
  // 2^0:redir, 2^1:notify, 2^2: show confirm, 2^3:auto
  NoRedir: 0, // b00
  NoRedirAndNotify: 1, // b01
  OnConfirm: 5, // b101
  Auto: 9, // b1001
};
Object.freeze(RedirBehavior);

export class APIMessage {
  static errormessage(data) {
    if (typeof data === 'string') {
      return data;
    } else if (Array.isArray(data)) {
      if (data.length > 0) return this.errormessage(data[0]);
      else return null;
    } else {
      const keynames = Object.keys(data);
      if (keynames.length > 0) {
        if (
          Array.isArray(data[keynames[0]]) ||
          data[keynames[0]] instanceof Object
        ) {
          return this.errormessage(data[keynames[0]]);
        } else {
          return `${keynames[0]}: ${data[keynames[0]]}`;
        }
      } else return null;
    }
  }

  static failed({ confirm }) {
    return `Communication with the server failed.${
      confirm ? '\n Will you try again?' : ''
    }`;
  }

  static servererrror({ confirm }) {
    return `An internal server error occurred. ${
      confirm ? '\n Will you try again?' : ''
    }`;
  }

  static async declined({ response, confirm }) {
    let declinemessage = '';
    try {
      const body = await response.json();
      declinemessage = this.errormessage(body);
    } catch (error) {
      // console.log(error)
      return `For some reason, we couldn't load. ${
        confirm ? '\n Can you give it another shot?' : ''
      }`;
    }
    if (HTTP_STATUS.isServerError(response.status)) {
      // TODO: Report
      return `Internal server errror happened, so we couldn't load.${
        confirm ? '\n Can you give it another shot?' : ''
      }`;
    }
    return `Server declined with message.\n${declinemessage}${
      confirm ? '\n Can you give it another shot?' : ''
    }`;
  }

  static notloggedin({ confirm }) {
    return `You are not logged in. (Or session expired.)${
      confirm ? '\n Will you redirect to login page?' : ''
    }`;
  }
}

// TODO: consider using axios, if any of its features is in need.
export class APIAgent {
  constructor({ serverurl }) {
    this.serverurl = serverurl;
    this.options = {
      retryDelay: 10_000,
    };
  }

  apiUrl(relativeUrl, params) {
    let url = this.serverurl;
    if (!url.endsWith('/')) {
      url += '/';
    }
    if (relativeUrl.startsWith('/')) {
      url += relativeUrl.substr(1);
    } else {
      url += relativeUrl;
    }
    if (!url.endsWith('/')) {
      url += '/';
    }
    if (params) {
      const searchparams = new URLSearchParams(params);
      url += '?' + searchparams;
    }
    return url;
  }

  static getFromDict(behaviorOptions, errorClass) {
    let reachedTop = false;
    do {
      reachedTop = errorClass.name === APICallError.name;
      if (errorClass.name in behaviorOptions) {
        return behaviorOptions[errorClass.name];
      }
      errorClass = errorClass.__proto__;
    } while (!reachedTop);
    if (Number.isInteger(behaviorOptions['Default'])) {
      return behaviorOptions['Default'];
    } else {
      throw new APIAgentError(
        null,
        `Behavior option not found - errorClass: '${
          errorClass.name
        }' options.redirBehavior: ${JSON.stringify(behaviorOptions)}`,
      );
    }
  }

  static getOptionForCase(options, errorClass) {
    if (redirableErrors.indexOf(errorClass) >= 0) {
      if ('redirBehavior' in options) {
        if (options.redirBehavior instanceof Object) {
          return this.getFromDict(options.redirBehavior, errorClass);
        } else if (Number.isInteger(options.redirBehavior)) {
          return options.redirBehavior;
        } else {
          throw new APIAgentError(
            null,
            `Unexpected data type - options.redirBehavior: ${JSON.stringify(
              options.redirBehavior,
            )}`,
          );
        }
      } else {
        // default
        // return RedirBehavior.NoRedir
        throw new APIAgentError(
          null,
          `Redirect behavior not defined - options: ${JSON.stringify(options)}`,
        );
      }
    } else if (retriableErrors.indexOf(errorClass) >= 0) {
      if ('retryBehavior' in options) {
        if (options.retryBehavior instanceof Object) {
          return this.getFromDict(options.retryBehavior, errorClass);
        } else if (Number.isInteger(options.retryBehavior)) {
          return options.retryBehavior;
        } else {
          throw new APIAgentError(
            null,
            `Unexpected data type - options.retryBehavior: ${JSON.stringify(
              options.retryBehavior,
            )}`,
          );
        }
      } else {
        // default
        // return retryBehavior.NoRetry
        throw new APIAgentError(
          null,
          `Retry behavior not defined - options: ${JSON.stringify(options)}`,
        );
      }
    } else {
      throw new APIAgentError(
        null,
        `Unexpected errorClass - '${errorClass.name}'`,
      );
    }
  }
  /**
   *
   * Currently supported body format: multipart/form and application/json
   * TODO: Support other body formats.
   * @param {string} path - path to API
   * @param {string} method - GET | POST | PUT | PATCH | DELETE
   * @returns Response
   * https://developer.mozilla.org/en-US/docs/Web/API/Response
   * .json() - to get response body
   * .status - HTTP status code
   * .ok - true if status in range (200,299)
   */
  async genericCall({ method, path, params, data, options }) {
    // body
    let body = null;
    let headers = {};
    if (data) {
      if (data instanceof FormData) {
        body = data;
      } else {
        body = JSON.stringify(data);
        headers['Content-Type'] = 'application/json';
      }
    }

    let domain = new URL(config.serverurl);
    domain = domain.hostname;
    if (domain === 'api.getflair.io') domain = 'csrftoken';
    else domain = 'csrftoken_staging';

    if (cookies.get(domain)) {
      headers['X-CSRFToken'] = cookies.get(domain);
    }

    // fetch
    let response;
    try {
      response = await fetch(this.apiUrl(path, params), {
        method,
        body,
        headers,
        redirect: 'follow',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
      });
    } catch (error) {
      // console.trace(error)
      // CommunicationError
      const behaviorOption = APIAgent.getOptionForCase(
        options,
        CommunicationError,
      );
      if (behaviorOption === RetryBehavior.NoRetry) {
        throw new CommunicationError(response);
      } else if (behaviorOption === RetryBehavior.NoRetryAndNotify) {
        await uxDialog.alert({ text: APIMessage.failed({ confirm: false }) });
        throw new CommunicationError(response);
      } else if (behaviorOption === RetryBehavior.OnConfirm) {
        if (
          await uxQueue.confirm('retry', APIMessage.failed({ confirm: true }))
        ) {
          await delay(this.options.retryDelay);
          return this.genericCall({ method, path, params, data, options });
        } else {
          throw new CommunicationError(response);
        }
      } else if (behaviorOption === RetryBehavior.Auto) {
        await delay(this.options.retryDelay);
        return this.genericCall({ method, path, params, data, options });
      }
    }
    if (response.ok) {
      return response;
    } else {
      if (
        response.status === HTTP_STATUS.UNAUTHORIZED ||
        response.status === HTTP_STATUS.FORBIDDEN
      ) {
        // console.log('@apicall', response.status)
        try {
          let responseBody = await response.json();
          // console.log('@apicall', responseBody.detail)
          if (
            responseBody.detail ===
            'Authentication credentials were not provided.'
          ) {
            // AuthenticationError
            const behaviorOption = APIAgent.getOptionForCase(
              options,
              AuthenticationError,
            );
            if (behaviorOption === RedirBehavior.NoRedir) {
              throw new AuthenticationError(response);
            } else if (behaviorOption === RedirBehavior.NoRedirAndNotify) {
              await uxDialog.alert({ text: APIMessage.notloggedin() });
              throw new AuthenticationError(response);
            } else if (behaviorOption === RedirBehavior.OnConfirm) {
              const confirmResult = await uxQueue.confirm(
                'redir',
                APIMessage.notloggedin({ confirm: true }),
              );
              if (confirmResult) {
                // console.trace('Redirecting', confirmResult)
                window.location.href =
                  config.serverurl + 'users/redirect_auth0_login_page/';
                throw new AuthenticationError(response);
              } else {
                throw new AuthenticationError(response);
              }
            } else if (behaviorOption === RedirBehavior.Auto) {
              // console.trace('Auto', options, behaviorOption)
              window.location.href =
                config.serverurl + 'users/redirect_auth0_login_page/';
              throw new AuthenticationError(response);
            }
          } else {
            // AuthorizationError
            const behaviorOption = APIAgent.getOptionForCase(
              options,
              AuthorizationError,
            );
            if (behaviorOption === RetryBehavior.NoRetry) {
              throw new AuthorizationError(response);
            } else if (behaviorOption === RetryBehavior.NoRetryAndNotify) {
              await uxDialog.alert({
                text: await APIMessage.declined(response),
              });
              throw new AuthorizationError(response);
            } else if (behaviorOption === RetryBehavior.OnConfirm) {
              if (
                await uxQueue.confirm(
                  'retry',
                  await APIMessage.declined({ response, confirm: true }),
                )
              ) {
                await delay(this.options.retryDelay);
                return this.genericCall({
                  method,
                  path,
                  params,
                  data,
                  options,
                });
              } else {
                throw new AuthorizationError(response);
              }
            } else if (behaviorOption === RetryBehavior.Auto) {
              await delay(this.options.retryDelay);
              return this.genericCall({ method, path, params, data, options });
            }
          }
        } catch (error) {
          if (error instanceof APICallError) {
            throw error;
          } else {
            throw new APIAgentError(error);
          }
        }
      } else if (HTTP_STATUS.isServerError(response.status)) {
        // InternalServerError - HTTP 5xx
        const behaviorOption = APIAgent.getOptionForCase(
          options,
          InternalServerError,
        );
        if (behaviorOption === RetryBehavior.NoRetry) {
          throw new InternalServerError(response);
        } else if (behaviorOption === RetryBehavior.NoRetryAndNotify) {
          await uxDialog.alert({
            text: APIMessage.servererrror({ confirm: false }),
          });
          throw new InternalServerError(response);
        } else if (behaviorOption === RetryBehavior.OnConfirm) {
          if (
            await uxQueue.confirm(
              'retry',
              APIMessage.servererrror({ confirm: true }),
            )
          ) {
            await delay(this.options.retryDelay);
            return this.genericCall({ method, path, params, data, options });
          } else {
            throw new InternalServerError(response);
          }
        } else if (behaviorOption === RetryBehavior.Auto) {
          await delay(this.options.retryDelay);
          return this.genericCall({ method, path, params, data, options });
        }
      } else {
        // ServerDeclinedError - HTTP 4xx
        // TODO: BadRequest only for HTTP 400, For HTTP 404 and other cases ServerDeclineError
        const behaviorOption = APIAgent.getOptionForCase(
          options,
          BadRequestError,
        );
        if (behaviorOption === RetryBehavior.NoRetry) {
          throw new BadRequestError(response);
        } else if (behaviorOption === RetryBehavior.NoRetryAndNotify) {
          await uxDialog.showAlert({
            text: await APIMessage.declined({ response, confirm: false }),
          });
          throw new BadRequestError(response);
        } else if (behaviorOption === RetryBehavior.OnConfirm) {
          if (
            await uxQueue.confirm(
              'retry',
              await APIMessage.declined({ response, confirm: true }),
            )
          ) {
            await delay(this.options.retryDelay);
            return this.genericCall({ method, path, params, data, options });
          } else {
            throw new BadRequestError(response);
          }
        } else if (behaviorOption === RetryBehavior.Auto) {
          await delay(this.options.retryDelay);
          return this.genericCall({ method, path, params, data, options });
        }
      }
      // If this line is reached it's abnormal
      throw new APIAgentError(
        null,
        `Unexpected case response.status: ${response.status} response: ${response}`,
      );
    }
  }

  get({ path, params, options }) {
    return this.genericCall({ method: 'GET', path, params, options });
  }

  post({ path, data, params, options }) {
    return this.genericCall({ method: 'POST', path, params, options, data });
  }

  patch({ path, data, params, options }) {
    return this.genericCall({ method: 'PATCH', path, params, options, data });
  }

  put({ path, data, params, options }) {
    return this.genericCall({ method: 'PUT', path, params, options, data });
  }

  del({ path, data, params, options }) {
    return this.genericCall({ method: 'DELETE', path, params, options, data });
  }
}

export const apiAgent = new APIAgent({ serverurl: config.serverurl });

const popularOptions = {
  onConfirm: {
    retryBehavior: RetryBehavior.OnConfirm,
    redirBehavior: RedirBehavior.OnConfirm,
  },
  onConfirmExceptDeclined: {
    retryBehavior: {
      ServerDeclinedError: RetryBehavior.NoRetryAndNotify,
      Default: RetryBehavior.OnConfirm,
    },
    redirBehavior: RedirBehavior.OnConfirm,
  },
  inBackground: {
    retryBehavior: RetryBehavior.Auto,
    redirBehavior: RedirBehavior.NoRedir,
  },
  straight: {
    retryBehavior: RetryBehavior.NoRetry,
    redirBehavior: RedirBehavior.NoRedir,
  },
};

apiAgent.popularOptions = popularOptions;
