import { useEffect, useState } from 'react';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import Select, { components } from 'react-select';
import { DateTime } from 'luxon';
import { Dropdown, Button } from '@restart/ui';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

import DayPickerBody from '../../components/daypicker/DayPickerBody';
import uxDialog from '../../utils/uxdialog';
import { apiAgent, HTTP_STATUS } from '../../utils/apicall';
import { svgIcons } from '../../components/svgIcons/svgIcons';
import { convertDateTime } from '../../utils/publicFunctions';
import { DropdownIndicator } from '../../components/select/Select';

import LinkModal from '../../components/modalbootstrap/LinkModal';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import config from '../../utils/config';
import { REPORT_LIST } from '../../utils/constants';

export default function Report() {
  const [all, setAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigator = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectList, setProjectList] = useState([]);

  const [image, setImage] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [downloadFlag, setDownloadFlag] = useState(0);
  const [height, setHeight] = useState([]);
  const [maxHeight, setMaxHeight] = useState(0);

  const [dic, setDic] = useState({ value: null, label: null });
  const [project, setProject] = useState({ value: null, label: null });
  const [modalShow, setModalShow] = useState(false);
  const [s, setS] = useState(['']);
  const [url, setURL] = useState(['url']);

  const [period, setPeriod] = useState([
    DateTime.fromJSDate(
      moment().subtract(1, 'month').startOf('month')._d,
    ).toFormat('yyyy-MM-dd'),
    DateTime.fromJSDate(
      moment().subtract(1, 'month').endOf('month')._d,
    ).toFormat('yyyy-MM-dd'),
  ]);
  // --> get project list
  useEffect(async () => {
    if (searchParams.get('period') && searchParams.get('period') !== 'null')
      setPeriod(searchParams.get('period').split('~'));
    var projectId = searchParams.get('project');
    var linkUrl = searchParams.get('link');
    var p = window.location.href;

    if (p.search('/s/') >= 0) {
      if (p.search('link=') == -1) {
        if (p.search('s=') == -1) window.location.href = 'failed';
        try {
          var pp = p.split('s=')[1];
          pp = pp.replaceAll('%3D', '=');
          setS(pp);
          const response = await apiAgent.get({
            path: '/reports/decode_token',
            params: {
              token: pp,
            },
            options: apiAgent.popularOptions.onConfirmExceptDeclined,
          });
          const responseDate = await response.json();
          var dics = {
            value: projectId,
            label: responseDate.data.project.name,
          };
          setDic(dics);
          if (responseDate.data == 'failed') window.location.href = 'failed';
          else {
            if (responseDate.data.project.id != projectId)
              window.location.href = 'failed';
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    if (linkUrl) {
      try {
        const response = await apiAgent.get({
          path: '/reports/decode_token',
          params: { token: linkUrl },
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        const responseDate = await response.json();
        window.location.href = `${p.split('?link=')[0]}${
          responseDate.data.page
        }?project=${
          responseDate.data.project.id
        }&period=${responseDate.data.period.join('~')}&s=${linkUrl}`;
        setS(linkUrl);
      } catch (error) {
        console.log(error);
      }
    } else {
      if (projectId) {
        fetchProjectId(projectId);
      } else {
        fetchProjects(false);
      }
    }
  }, []);

  const fetchProjects = async (flag) => {
    var ordering = 'property__name';
    let params = { offset: 0, limit: 1, ordering };
    if (flag) {
      params = { offset: 0, limit: 9999, ordering };
    }
    try {
      var path = '/projects/';
      if (window.location.href.search('/s/') >= 0) path = '/reports/';
      const response = await apiAgent.get({
        path: path,
        params: params,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const responseDate = await response.json();
      let pjList = [];
      if (flag) {
        setAll(false);
        pjList = responseDate.results.map((item) => {
          return { value: item.id, label: item.property.name };
        });
        setIsLoading(false);
      } else {
        setAll(true);
        pjList = [
          {
            value: responseDate.results[0].id,
            label: responseDate.results[0].property.name,
          },
          {
            value: 0,
            label: 'Loading...',
            isDisabled: true,
          },
        ];
      }
      setProjectList(pjList);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjectId = async (projectId) => {
    var ordering = 'property__name';
    let params = { offset: 0, limit: 1, ordering };
    try {
      var path = `/projects/${projectId}`;
      if (window.location.href.search('/s/') >= 0)
        path = `/reports/${projectId}`;
      const response = await apiAgent.get({
        path: path,
        params,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const responseDate = await response.json();
      setAll(true);
      var pjList = [
        {
          value: responseDate.id,
          label: responseDate.property.name,
        },
        {
          value: 0,
          label: 'Loading...',
          isDisabled: true,
        },
      ];
      setProjectList(pjList);
    } catch (error) {
      console.log(error);
    }
  };
  // get project list <--

  useEffect(() => {
    if (projectList.length)
      if (all) {
        setProject(projectList[0]);
      }
    if (all) fetchProjects(true);
  }, [projectList]);

  useEffect(() => {
    if (project.value) {
      if (window.location.href.search('/s/') == -1)
        setSearchParams({
          project: project.value,
          period: period.join('~'),
        });
      else
        setSearchParams({
          project: project.value,
          period: period.join('~'),
          s: s,
        });
    }
  }, [project, period, s]);

  const shareLink = async () => {
    var str = window.location.href.split('?')[0].split('/report/')[1];
    var startDate = searchParams.get('period').split('~')[0];
    var endDate = searchParams.get('period').split('~')[1];
    var projectId = searchParams.get('project');
    var pageNumber = 0;

    if (str == 'budget-overview') pageNumber = 1;
    if (str == 'conversion-overview') pageNumber = 2;
    if (str == 'keyword-performance') pageNumber = 3;
    if (str == 'demographic-performance') pageNumber = 4;

    var params = {
      project_id: projectId,
      page_number: pageNumber,
      start_date: startDate,
      end_date: endDate,
    };
    try {
      const response = await apiAgent.get({
        path: '/reports/create_report_token',
        params: params,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const responseDate = await response.json();
      var p = window.location.href;
      setURL(p.split('report/')[0] + 'share/' + responseDate.token);
      setModalShow('true');
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirm = (userInput) => {
    var copy = function (e) {
      e.preventDefault();
      console.log('copy');
      var text = url;
      if (e.clipboardData) {
        e.clipboardData.setData('text/plain', text);
      } else if (window.clipboardData) {
        window.clipboardData.setData('Text', text);
      }
    };
    window.addEventListener('copy', copy);
    document.execCommand('copy');
    window.removeEventListener('copy', copy);
    setModalShow(false);
  };

  const download = async () => {
    uxDialog.alert({
      text: "We're preparing your PDF report, it will appear in your downloads shortly.",
    });
    if (downloadFlag == 0) {
      setDownloadFlag(1);
      setPageNum(0);
      setImage([]);
      var startDate = searchParams.get('period').split('~')[0];
      var endDate = searchParams.get('period').split('~')[1];
      var projectId = searchParams.get('project');

      try {
        var p = window.location.href;

        const response = await apiAgent.get({
          path: '/reports/make_file',
          params: {
            project_id: projectId,
            start_date: startDate,
            end_date: endDate,
            url: p.split('report/')[0] + 's/?link=',
          },
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        if (HTTP_STATUS.isSuccess(response.status)) {
          const responseDate = await response.json();
          downloadUrl(
            config.serverurl +
              'reports/make_file/?get_file=' +
              responseDate.name +
              '&project_id=' +
              projectId,
          );
        }
        setDownloadFlag(0);
      } catch (error) {
        console.log(error);
        setDownloadFlag(0);
      }
    }
  };

  const downloadPDF = async (e) => {
    var p = window.location.href;
    p = p.split('?')[0].split('/').slice(-1)[0];
    const input = document.getElementById(p);

    if (pageNum == 0) {
      var cover = document.getElementById('cover');
      cover.style.display = 'block';
      var coverCenter = document.getElementById('cover-center');
      html2canvas(cover).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        var height = parseInt(canvas.style.height) / 2 - 100;
        coverCenter.style.paddingTop = height.toString() + 'px';
        var h = parseInt(canvas.style.height) / 6;
        let Data = [imgData],
          Data1 = [h];
        setImage(Data);
        setHeight(Data1);
        if (h > maxHeight) setMaxHeight(h);
        setPageNum(1);
      });
    } else {
      var cover = document.getElementById('cover');
      cover.style.display = 'none';
      var title = document.createElement('h5');
      var txt = REPORT_LIST[pageNum - 1].split('-');
      var txtFirst = txt[0].charAt(0).toUpperCase() + txt[0].slice(1);
      var txtLast = txt[1].charAt(0).toUpperCase() + txt[1].slice(1);
      title.innerText = txtFirst + ' ' + txtLast;
      title.style.paddingBottom = '20px';
      input.insertBefore(title, input.firstChild);

      if (
        p == 'account-overview' ||
        p == 'budget-overview' ||
        p == 'keyword-performance'
      )
        document.getElementById(p + '-pag').style.display = 'none';

      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        let Data = [],
          Data1 = [];
        var i = 0;
        for (i = 0; i < pageNum; i++) Data[i] = image[i];
        Data[i] = imgData;
        setImage(Data);

        var h = parseInt(canvas.style.height) / 6;
        for (i = 0; i < pageNum; i++) Data1[i] = height[i];
        Data1[i] = h;
        setHeight(Data1);
        if (h > maxHeight) setMaxHeight(h);
        setPageNum(i + 1);
        if (pageNum == 5) {
          var w = parseInt(canvas.style.width) / 6;
          var doc = new jsPDF('p', 'pt', [w + 10, maxHeight + 10], true);
          for (let i = 0; i < pageNum; i++) {
            doc.addImage(
              image[i],
              'PNG',
              5,
              5,
              w,
              height[i],
              undefined,
              'FAST',
            );
            doc.addPage();
          }
          doc.addImage(imgData, 'PNG', 5, 5, w, h, undefined, 'FAST');
          doc.save('Report.pdf');
        }
      });
    }
  };

  const downloadUrl = (dataurl) => {
    const link = document.createElement('a');
    link.setAttribute('download', '');
    link.href = dataurl;
    link.click();
  };

  const handleClose = () => {
    setModalShow(false);
  };
  return (
    <div className="flex">
      <div className="hidden md:block h-screen sticky top-0 bg-white py-6 w-fit">
        <div className="flex justify-between items-center px-8 mb-5">
          <div className="font-bold leading-7 text-lg">Performance</div>
          {/* <button className="w-9 h-9 rounded-full bg-[#687BFE]/[0.2] text-[#687BFE] flex items-center justify-center indent-2">
            <div className="w-[9px] h-[9px]">{svgIcons.plus}</div>
          </button> */}
        </div>
        {REPORT_LIST.map((item, index) => (
          <Link
            key={index}
            to={item + location.search}
            className={
              (item === location.pathname.split('/').pop() && 'bg-[#E6EFFB]') +
              ' block font-medium hover:text-[#161D37] text-base py-2 px-8 mt-3 whitespace-nowrap capitalize'
            }
            id={item + '-link'}
          >
            {item.replace('-', ' ')}
          </Link>
        ))}
      </div>
      <div className="grow grid grid-cols-1 py-6 md:py-8 px-5 md:px-10">
        {window.location.href.search('/s/') != -1 && (
          <button
            onClick={downloadPDF}
            style={{ width: '1px', color: 'rgb(59 130 246 / 0.02)' }}
            id="download_button"
          >
            .
          </button>
        )}
        <div
          style={{
            backgroundColor: 'white',
            width: '100%',
            display: 'none',
          }}
          id="cover"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '170px',
            }}
          >
            <div>
              <h5 className="flex justify-between items-center w-40">
                {svgIcons.logo}
              </h5>
            </div>
          </div>
          <div
            id="cover-center"
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '350px',
            }}
          >
            <h5 style={{ fontSize: '40px' }}>{dic.label}</h5>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '5px',
            }}
          >
            <h5 style={{ fontSize: '40px' }}>Performance Report</h5>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '5px',
              paddingBottom: '50px',
            }}
          >
            <h5 style={{ fontSize: '26px' }}>
              {window.location.href
                .split('period=')
                .pop()
                .split('&')[0]
                .replace('%7E', ' ~ ')}
            </h5>
          </div>
        </div>
        <div>
          <div className="flex justify-between items-center">
            <div></div>

            {projectList.length ? (
              <Select
                placeholder="Select Property"
                styles={projectSelectStyle}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                  Control,
                }}
                options={
                  window.location.href.search('/s/') == -1 ? projectList : []
                }
                onChange={setProject}
                value={window.location.href.search('/s/') == -1 ? project : dic}
                isLoading={isLoading}
                className="z-10"
              />
            ) : (
              <Skeleton
                width={250}
                height={42}
                borderRadius={32}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            )}
          </div>
          <h5 className="hidden md:block mt-6 capitalize">
            {location.pathname.split('/').pop().replace('-', ' ')}
          </h5>
          <h5 className="md:hidden mt-6 capitalize">performance</h5>
          <div className="md:hidden mt-8 relative">
            <Dropdown>
              <Dropdown.Toggle>
                {(props) => (
                  <Button
                    {...props}
                    className="flair-btn flair-btn-md flair-btn-quaternary leading-[20.8px] w-full capitalize px-5 justify-between"
                  >
                    <div className="indent-3 flex items-center">
                      <div className="w-4">{svgIcons.report}</div>
                      {location.pathname.split('/').pop()}
                    </div>
                    <div className="w-2">
                      {props['aria-expanded']
                        ? svgIcons.dropup
                        : svgIcons.dropdown}
                    </div>
                  </Button>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {(menuProps, meta) => (
                  <div
                    {...menuProps}
                    className="p-5 grid gap-3 bg-white absolute top-12 w-full z-40 rounded-2"
                    style={{ visibility: meta.show ? 'visible' : 'hidden' }}
                  >
                    <div className="font-normal text-[14px] leading-[18.2px] text-[#8C94A3] capitalize">
                      category
                    </div>
                    {REPORT_LIST.map((item, index) => (
                      <label className="radio-container capitalize" key={index}>
                        {item.replace('-', ' ')}
                        <input
                          type="radio"
                          name="performance"
                          value={item}
                          checked={item === location.pathname.split('/').pop()}
                          onChange={(e) => {
                            meta.toggle();
                            navigator(e.target.value + location.search);
                          }}
                        />
                        <span className="radiomark"></span>
                      </label>
                    ))}
                  </div>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div
            className="py-6 border-b border-b-[#6F728B21] flex flex-wrap gap-6 justify-between items-center"
            style={{ float: 'right' }}
          >
            <div className="flex gap-6 flex-wrap relative">
              {window.location.href.search('/s/') == -1 && (
                <button
                  className="flair-btn flair-btn-md flair-btn-secondary"
                  onClick={download}
                  style={{ width: '150px' }}
                >
                  <div className="w-[15px]">{svgIcons.download}</div>
                  &nbsp;&nbsp;Download PDF
                </button>
              )}
            </div>
            <div className="flex gap-6 flex-wrap relative">
              <Dropdown>
                <Dropdown.Toggle>
                  {(props) => (
                    <Button
                      {...props}
                      className="flair-btn flair-btn-md flair-btn-quaternary w-auto pl-5 pr-4 font-normal text-base"
                      style={
                        props['aria-expanded']
                          ? {
                              border: '1px solid #687BFE',
                              color: '#687BFE',
                            }
                          : {}
                      }
                    >
                      <div
                        className="w-[15px] opacity-50"
                        style={
                          props['aria-expanded'] ? { color: '#687BFE' } : {}
                        }
                      >
                        {svgIcons.calendar_filled}
                      </div>
                      <div>
                        {period
                          .map((item) => {
                            return DateTime.fromSQL(item).toFormat('LLL d');
                          })
                          .join('~')}
                      </div>
                      <div className="w-2">
                        {props['aria-expanded']
                          ? svgIcons.dropup
                          : svgIcons.dropdown}
                      </div>
                    </Button>
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {(menuProps, meta) => (
                    <ul
                      {...menuProps}
                      className="fixed w-full xs:w-auto bottom-0 xs:absolute xs:bottom-auto xs:top-10 right-0 z-10 rounded-lg bg-white border border-[#E9ECF1] shadow-[0_20.3px_37.71px_rgba(183, 188, 200, 0.11)] whitespace-nowrap flex"
                      style={{
                        boxShadow:
                          '0px 20.3px 37.71px rgba(183, 188, 200, 0.11)',
                        transition:
                          'visibility 500ms, opacity 500ms, transform 500ms',
                        visibility: meta.show ? 'visible' : 'hidden',
                        opacity: meta.show ? '1' : '0',
                        transform: meta.show
                          ? 'translateY(6px)'
                          : 'translateY(-4px)',
                      }}
                    >
                      <DayPickerBody
                        onClickApply={async ({
                          from,
                          to,
                          fromRight,
                          toRight,
                        }) => {
                          try {
                            meta.toggle();
                            if (from == null && to == null)
                              throw 'Please select period.';
                            setPeriod(
                              [
                                convertDateTime(from),
                                convertDateTime(to),
                                convertDateTime(fromRight),
                                convertDateTime(toRight),
                              ]
                                .filter((item) => item !== null)
                                .map((item) => item.toFormat('yyyy-MM-dd')),
                            );
                          } catch (error) {
                            await uxDialog.alert({ text: error });
                          }
                        }}
                        onClickCancel={() => meta.toggle()}
                      />
                    </ul>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div>
              {window.location.href.search('/s/') == -1 && (
                <button
                  className="flair-btn flair-btn-lg flair-btn-primary w-[132px] h-[42px]"
                  onClick={shareLink}
                >
                  <img src="/assets/images/share.png" />
                  &nbsp;&nbsp;Share
                </button>
              )}
              <LinkModal
                show={modalShow}
                showInput={true}
                onHide={() => setModalShow(false)}
                onClose={() => setModalShow(false)}
                linkUrl={url}
                mark={{
                  icon: <img src="/assets/images/copy_link_mark.png" />,
                  parentClass: 'text-[#687BFE] bg-[#687BFE]/10',
                  childClass: 'w-[23.54px] h-[23.54px]',
                }}
                head="exists"
                handleClose={handleClose}
                headTitle="Get a link to this report"
                txt="Anyone on the internet with the link can view"
                handleConfirm={handleConfirm}
              />
            </div>
          </div>
        </div>
        <Outlet context={[project && project.value, period]} />
      </div>
    </div>
  );
}

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <span className="w-4">{svgIcons.property}</span>
      {children}
    </components.Control>
  );
};

const projectSelectStyle = {
  control: (styles, state, base, css) => ({
    ...base,
    ...styles,
    ...css,
    paddingLeft: '1rem',
    boxShadow: styles.isFocused ? 'null' : 'null',
    borderColor: state.isFocused ? '#687bfe' : '#cccccc',
    backgroundColor: state.isFocused ? '#FFFFFF' : '#f5faff',
    '&:hover': {
      cursor: 'pointer',
      borderColor: 'black',
    },
    borderRadius: '30px',
    outerHeight: '42px',
    padding: '2px 1.2rem',
  }),
  container: (base) => ({
    ...base,
    minWidth: 291,
  }),
  DropdownIndicator: (base) => ({
    ...base,
    padding: '0px',
    outerWidth: '10px',
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 10,
    marginTop: 5,
    paddingTop: 5,
    paddingBottom: 5,
  }),
  option: (base) => ({
    ...base,
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: 'black',
    borderRadius: 10,
  }),
  menuList: (style) => ({
    ...style,
    padding: 0,
    '::-webkit-scrollbar': {
      width: '5px',
      height: '100%',
    },
    '::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px #e6effb',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#b0c5de',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#687bfe',
    },
  }),
};
