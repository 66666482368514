import { configureStore } from '@reduxjs/toolkit';
import unreadNotificationsReducer from '../features/notifications/unreadNotificationsSlice';
import uxPromptSliceReducer from '../features/uxdialog/uxpromptSlice';
import searchPhraseSliceReducer from '../features/searchphrase/searchPhraseSlice';
import uxConfirmSliceReducer from '../features/uxdialog/uxconfirmSlice';
import uxAlertSliceReducer from '../features/uxdialog/uxalertSlice';
import userInfoSliceReducer from '../features/userInfo/userInfoSlice';
import loadingSliceReducer from '../features/loading/loadingSlice';

const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('userInfo', serializedState);
  } catch (e) {
    // handle the error here
    console.error('Could not save state to localStorage:', e);
  }
};

export const store = configureStore({
  reducer: {
    loading: loadingSliceReducer,
    userInfo: userInfoSliceReducer,
    unreadNotifications: unreadNotificationsReducer,
    uxPrompt: uxPromptSliceReducer,
    searchPhrases: searchPhraseSliceReducer,
    uxConfirm: uxConfirmSliceReducer,
    uxAlert: uxAlertSliceReducer,
  },
  devTools: true,
});

store.subscribe(() => {
  saveToLocalStorage(store.getState().userInfo.userInfo);
});
