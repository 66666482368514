import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setUserInfo } from '../../features/userInfo/userInfoSlice';
import { apiAgent } from '../../utils/apicall';
import SuccessModal from '../../components/modalbootstrap/SuccessModal';
import { svgIcons } from '../../components/svgIcons/svgIcons';
import UploadHighlightPhoto from '../../components/imageupload/UploadHighlightPhoto';
import { showLoading } from '../../features/loading/loadingSlice';

export default function Profile() {
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(null);
  const formRef = useRef();

  const handleSubmit = async () => {
    const formData = new FormData(formRef.current);
    try {
      dispatch(showLoading('Saving user...'));
      const response = await apiAgent.patch({
        path: `/users/${userInfo.id}`,
        data: formData,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const body = await response.json();
      dispatch(setUserInfo(body));
    } catch (error) {
      console.log(error);
    }
    dispatch(showLoading(false));
  };

  useEffect(() => {
    // Set page title and save navigation history
    const pageTitle = 'User Profile';
    window.document.title = `${pageTitle}`;
  }, []);

  return (
    <div className="px-0 xs:px-[40px] pt-10 h-full flex flex-col justify-between">
      <form ref={formRef}>
        <div>
          <h5 className="px-[20px] xs:px-0">Profile</h5>
          <div className="white-background">
            <UploadHighlightPhoto
              title="Photo"
              btnCaption="Upload"
              imgSrc={userInfo.photo || '/assets/images/tile-noimg.svg'}
              name="photo"
            />
            <div className="grid gap-6 my-8 py-8 border-y border-[#E9ECF1]">
              <div className="flex flex-col lg:flex-row gap-6 ">
                <div className="grid gap-2 w-full lg:w-96">
                  <div className="font-normal text-[14px] leading-[18.2px]">
                    First name
                  </div>
                  <input
                    className="flair-input"
                    type="text"
                    name="first_name"
                    value={userInfo.first_name || ''}
                    onChange={(e) =>
                      dispatch(
                        setUserInfo({
                          ...userInfo,
                          first_name: e.target.value,
                        }),
                      )
                    }
                  />
                </div>
                <div className="grid gap-2 w-full lg:w-96">
                  <div className="font-normal text-[14px] leading-[18.2px]">
                    Last name
                  </div>
                  <input
                    className="flair-input"
                    type="text"
                    name="last_name"
                    value={userInfo.last_name || ''}
                    onChange={(e) => {
                      dispatch(
                        setUserInfo({
                          ...userInfo,
                          last_name: e.target.value,
                        }),
                      );
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col lg:flex-row gap-6">
                <div className="grid gap-2 w-full lg:w-96">
                  <div className="font-normal text-[14px] leading-[18.2px]">
                    Email address
                  </div>
                  <input
                    className="flair-input"
                    type="email"
                    value={userInfo.email || ''}
                    disabled
                  />
                </div>
                {/* <div className="grid gap-2 w-full lg:w-96">
                <div className="font-normal text-[14px] leading-[18.2px]">
                  Phone number
                </div>
                <input className="flair-input" type="number" />
              </div> */}
              </div>
            </div>

            {/* <div className="">
            <div className="grid gap-2 w-full lg:w-96">
              <div className="font-normal text-[14px] leading-[18.2px]">
                Password
              </div>
              <input className="flair-input" type="password" />
              <a
                className="text-right font-normal text-[14px] leading-[15.4px] underline cursor-pointer"
                onClick={handleChangepwd}
              >
                Change password
              </a>
            </div>
          </div> */}
          </div>
        </div>

        <div className="py-12 flex flex-col-reverse gap-4 sm:flex-row justify-end px-[20px] xs:px-0">
          <button
            type="button"
            onClick={() => {}}
            className="flair-btn flair-btn-lg flair-btn-secondary sm:w-[220px]"
          >
            Cancel
          </button>
          <button
            className="flair-btn flair-btn-lg flair-btn-primary sm:w-[220px]"
            type="button"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </form>

      {/* <ResetPasswordModal
        show={modalShow === 'reset password'}
        onHide={() => setModalShow(null)}
        onClose={() => setModalShow(null)}
        handleConfirm
      /> */}

      {/* <SuccessModal
        show={modalShow === 'reset password success'}
        onHide={() => setModalShow(null)}
        onClose={() => setModalShow(null)}
        mark={{
          icon: svgIcons.success,
          parentClass: 'text-[#38D086] bg-[#38D086]/10',
          childClass: 'w-[30px] h-[17px]',
        }}
        title="Success"
        txt="Password was successfully changed, now you can use it to enter the system"
        modalConfirmBtn="Back to Profile"
        handleSuccess={() => setModalShow(null)}
      /> */}

      <SuccessModal
        show={modalShow === 'profile saved'}
        onHide={() => setModalShow(null)}
        onClose={() => setModalShow(null)}
        mark={{
          icon: svgIcons.success,
          parentClass: 'text-[#38D086] bg-[#38D086]/10',
          childClass: 'w-[30px] h-[17px]',
        }}
        title="Success"
        txt="The data was successfully saved"
        modalConfirmBtn="Back to Profile"
        handleSuccess={() => setModalShow(null)}
      />
    </div>
  );
}
