import { createSlice } from '@reduxjs/toolkit';

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: {
    userInfo: null,
    properties: [],
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setProperties: (state, action) => {
      state.properties = action.payload;
    },
  },
});

export const { setUserInfo, setProperties } = userInfoSlice.actions;

export default userInfoSlice.reducer;
