import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { memo, useEffect } from 'react';
import { DropdownIndicator } from '../select/Select';
import { svgIcons } from '../svgIcons/svgIcons';
import { useRef } from 'react';

const Paginater = ({
  total,
  pageIndex,
  pageLimit,
  onChangePageLimit,
  onChangePageIndex,
}) => {
  const pageIndexRef = useRef();

  useEffect(() => {
    pageIndexRef.current.value = pageIndex + 1;
  }, []);

  // useEffect(() => {
  //   onChangePageIndex(0);
  // }, [total]);

  return (
    <div className="pt-8 pb-12 flex flex-col gap-4 md:flex-row md:flex-wrap md:justify-end items-center">
      <ReactPaginate
        onPageChange={(e) => {
          onChangePageIndex(parseInt(e.selected));
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        pageCount={Math.ceil(total / pageLimit)}
        breakLabel="..."
        previousLabel={<div className="w-[6px]">{svgIcons.left_arrow}</div>}
        nextLabel={<div className="w-[6px]">{svgIcons.right_arrow}</div>}
        containerClassName="flex gap-2 h-[35px] text-[14px] leading-[1.3] font-normal"
        pageClassName="flex items-center px-3 border-b-[3px] border-transparent"
        previousClassName="flex items-center px-3 border-b-[3px] border-transparent"
        nextClassName="flex items-center px-3 border-b-[3px] border-transparent"
        breakClassName="flex items-center px-3 border-b-[3px] border-transparent"
        activeClassName="border-b-[#687BFE] text-[#687BFE]"
        renderOnZeroPageCount={null}
        forcePage={pageIndex}
      />
      <div className="flex items-center">
        <div className="md:pl-4 pr-4 md:border-l border-l-[#E9ECF1] font-medium text-[14px] leading-[18.2px]">
          Go to page
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onChangePageIndex(pageIndexRef.current.value - 1);
          }}
        >
          <input
            className="w-10 h-10 pl-2 border border-[#F2F5F9] rounded"
            type="number"
            max={Math.ceil(total / pageLimit)}
            ref={pageIndexRef}
          />
        </form>
        <div className="px-4 border-r border-[#E9ECF1] mr-4">of {total}</div>

        <Select
          components={{ DropdownIndicator, IndicatorSeparator: () => null }}
          value={PAGE_COUNTS.find((item) => item.value === pageLimit)}
          onChange={(e) => {
            onChangePageLimit(e.value);
            onChangePageIndex(0);
          }}
          options={PAGE_COUNTS}
          clearable={true}
          menuPlacement="auto"
          styles={{
            control: (styles, state, base, css) => ({
              ...base,
              ...styles,
              ...css,
              boxShadow: state.menuIsOpen ? '0px 0px 0px 4px #687BFE40' : null,
              borderColor: state.menuIsOpen ? '#687bfe' : '#F2F5F9',
              '&:hover': {
                cursor: 'pointer',
                borderColor: '#687bfe',
              },
            }),
            container: (base) => ({
              ...base,
            }),
            menu: (base) => ({
              ...base,
              borderRadius: 4,
              marginTop: 4,
            }),
            option: (base) => ({
              ...base,
              borderWidth: 0,
              borderRadius: 4,
              padding: '11px 12px',
            }),
          }}
        />
      </div>
    </div>
  );
};

const PAGE_COUNTS = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 20, label: '20' },
];

export default memo(Paginater);
