import { useState, useEffect, memo } from 'react';
import { useOutletContext } from 'react-router-dom';

import { DateTime } from 'luxon';
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import ReactTooltip from 'react-tooltip';
import Skeleton from 'react-loading-skeleton';

import { apiAgent } from '../../utils/apicall';
import { getDateArr } from '../../utils/publicFunctions';
import { svgIcons } from '../../components/svgIcons/svgIcons';
import { ContainerWithBar } from '../../components/Container/ContainerWithChart';

const ConversionOverview = () => {
  const [project, period] = useOutletContext();

  const [labelsByDate, setLabelsByDate] = useState([]); // chart label

  const [metricsData, setMetricsData] = useState({
    costPerConversion: [],
    costs: [],
    conversions: [],
    interactions: [],
  });
  const [conversionData, setConversionData] = useState([]);

  const [infoLoaded, setInfoLoaded] = useState(false);

  const fetchOverview = async () => {
    try {
      setInfoLoaded(false);
      var path = '/projects/';
      if (
        window.location.href.search('/s/') >= 0 ||
        window.location.href.search('/share/') >= 0
      )
        path = '/reports/';
      const response = await apiAgent.get({
        path: path + `${project}/conversion_overview/`,
        params: {
          period,
        },
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const data = await response.json();

      // set data
      const dateArr = getDateArr(period);
      setLabelsByDate(
        dateArr.map((item) => DateTime.fromSQL(item).toFormat('LLL d')),
      );

      setMetricsData(() =>
        data.data_per_date.reduce(
          (obj, item) => {
            let index = dateArr.indexOf(item.segments.date);
            obj.conversions[index] = item.metrics.conversions || 0;
            obj.costs[index] = item.metrics.costMicros / 1000000 || 0;
            obj.interactions[index] = item.metrics.interactions / 1 || 0;
            obj.costPerConversion[index] =
              item.metrics.costPerConversion / 1000000 || 0;
            return obj;
          },
          {
            conversions: Array(dateArr.length).fill(0),
            costs: Array(dateArr.length).fill(0),
            interactions: Array(dateArr.length).fill(0),
            costPerConversion: Array(dateArr.length).fill(0),
          },
        ),
      );

      setConversionData(() =>
        data.data_per_conversion.map((item) => {
          return {
            name: item.segments.conversionActionName,
            value: item.metrics.conversions,
          };
        }),
      );
      setInfoLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!period.length || !project) {
      return;
    }
    fetchOverview();
  }, [period, project]);

  return project && infoLoaded ? (
    <div className="py-6" id="conversion-overview">
      <div className="bg-white px-8 py-7 border border-[#E9ECF1] rounded">
        <div className="mb-8 flex justify-between items-start flex-wrap gap-3">
          <div className="grid gap-1">
            <div className="font-semibold text-[18px] capitalize flex items-center gap-2">
              Average Spend Per Day
              <div
                className="w-4 text-[#B7BCC8]"
                data-for="chart-info"
                data-tip="The average amount that you spent day by day"
              >
                {svgIcons.exclamation}
              </div>
            </div>
            <div className="flex gap-7">
              <div className="flex gap-1 ml-1 items-center">
                <div className="bg-[#687BFE] w-[10px] h-[10px] rounded-full"></div>
                <div className="text-xs font-medium">Conversions</div>
              </div>
              <div className="flex gap-1 ml-1 items-center">
                <div className="bg-[#EA7E32] w-[10px] h-[10px] rounded-full"></div>
                <div className="text-xs font-medium">Cost</div>
              </div>
            </div>
          </div>
          <div className="flex gap-8">
            <div>
              <p className="text-[18px] font-semibold text-right">
                $
                {parseFloat(
                  metricsData.costs
                    .reduce((sum, item) => (sum += item || 0), 0)
                    .toFixed(2),
                ).toLocaleString() || 0}
              </p>
              <p className="font-medium text-[12px] leading-[15.6px] capitalize text-right">
                Cost
              </p>
            </div>
            <div>
              <p className="text-[18px] font-semibold text-right">
                {parseFloat(
                  metricsData.conversions
                    .reduce((sum, item) => (sum += item || 0), 0)
                    .toFixed(2),
                ).toLocaleString() || 0}
              </p>
              <p className="font-medium text-[12px] leading-[15.6px] capitalize text-right">
                Conversions
              </p>
            </div>
            <div>
              <p className="text-[18px] font-semibold text-right">
                {(
                  (metricsData.conversions.reduce(
                    (sum, item) => (sum += item || 0),
                    0,
                  ) /
                    metricsData.interactions.reduce(
                      (sum, item) => (sum += item || 0),
                      0,
                    ) || 0) * 100
                ).toFixed(2)}
                %
              </p>
              <p className="font-medium text-[12px] leading-[15.6px] capitalize text-right">
                Conv.rate
              </p>
            </div>
          </div>
        </div>
        <div className="h-60">
          <div className="flex gap-3 items-center">
            <div className="text-[#687BFE] w-3">{svgIcons.filter}</div>
            <div className="text-[#EA7E32] w-3">{svgIcons.dollar}</div>
          </div>
          <Line
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  borderColor: '#E9EBF1',
                  borderWidth: 1,
                  borderRadius: 4,
                  displayColors: true,
                  bodySpacing: 10,
                  backgroundColor: 'white',
                  bodyColor: '#515868',
                  titleColor: '#161D37',
                  usePointStyle: true,
                  callbacks: {
                    labelPointStyle: function (context) {
                      return {
                        pointStyle: 'circle',
                      };
                    },
                  },
                },
              },
              interaction: {
                intersect: false,
                mode: 'index',
              },
              elements: {
                line: {
                  tension: 0.4,
                },
                point: {
                  radius: 0,
                  hoverRadius: 8,
                },
              },
            }}
            data={{
              labels: labelsByDate,
              datasets: [
                {
                  label: 'Conversions',
                  borderColor: '#687BFE',
                  fill: false,
                  data: metricsData.conversions,
                  backgroundColor: '#687BFE',
                  hoverBackgroundColor: '#ffffff',
                },
                {
                  label: 'Cost',
                  borderColor: '#EA7E32',
                  fill: false,
                  data: metricsData.costs,
                  backgroundColor: '#EA7E32',
                  hoverBackgroundColor: '#ffffff',
                },
              ],
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 lg:gap-6 mt-6 lg:mt-10 lg:grid-cols-5">
        <div className="bg-white p-4 lg:col-span-2 border border-[#E9ECF1] rounded">
          <div className="text-[18px] font-semibold flex items-center gap-2">
            Conversions by Type
            <div
              className="w-4 text-[#B7BCC8]"
              data-for="chart-info"
              data-tip="Different types of conversions for the specified triggers"
            >
              {svgIcons.exclamation}
            </div>
          </div>
          <div className="mt-6">
            <div className="max-w-xs m-auto">
              <Doughnut
                options={{
                  plugins: {
                    legend: {
                      title: {
                        padding: 100,
                      },
                      position: 'bottom',
                      align: 'start',
                      labels: {
                        usePointStyle: true,
                        boxWidth: 12,
                        padding: 10,
                      },
                    },
                    tooltip: {
                      borderColor: '#E9EBF1',
                      borderWidth: 1,
                      borderRadius: 4,
                      displayColors: true,
                      bodySpacing: 10,
                      backgroundColor: 'white',
                      bodyColor: '#515868',
                      titleColor: '#161D37',
                      usePointStyle: true,
                      callbacks: {
                        labelPointStyle: function (context) {
                          return {
                            pointStyle: 'circle',
                          };
                        },
                      },
                    },
                  },
                }}
                data={{
                  labels: conversionData.map((item) => item.name),
                  datasets: [
                    {
                      label: 'Conversion Type',
                      data: conversionData.map((item) => item.value),
                      backgroundColor: [
                        '#A8B1EF',
                        '#FF7F5F',
                        '#38D086',
                        '#F6D214',
                        '#DB7DF2',
                        '#59D3EE',
                      ],
                      hoverOffset: 4,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <ContainerWithBar
          className="lg:col-span-3"
          title="Cost per Conversion"
          sub-title="cost per conversion"
          statistics={(
            metricsData.costs.reduce((sum, item) => (sum += item || 0), 0) /
              metricsData.conversions.reduce(
                (sum, item) => (sum += item || 0),
                0,
              ) || 0
          ).toFixed(2)}
          data-tip="How much money is spent on an ad campaign, divided by the number of conversions (customers) over the same period"
          color="#A8B1EF"
          prefix="$ "
          chart-label="Conversions"
          chart-data-labels={labelsByDate}
          chart-data={metricsData.costPerConversion}
        />
      </div>
      <ReactTooltip
        id="chart-info"
        place="right"
        type="light"
        effect="solid"
        multiline={true}
        className="w-40"
      />
    </div>
  ) : (
    <div className="py-6">
      <div className="skeleton-background px-8 py-7">
        <div className="mb-6 flex gap-6 justify-between items-start flex-wrap">
          <div className="grid gap-1">
            <p className="text-[18px]">
              <Skeleton
                width={270}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </p>
            <div className="flex gap-7">
              <p className="text-sm flex gap-1 ml-1 items-center">
                <Skeleton
                  width={50}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </p>
              <p className="text-[12px] flex gap-1 ml-1 items-center">
                <Skeleton
                  width={40}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </p>
            </div>
          </div>
          <div className="ml-auto flex gap-8">
            <div>
              <p className="text-[18px] text-right">
                <Skeleton
                  width={50}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </p>
              <p className="text-sm text-right">
                <Skeleton
                  width={30}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </p>
            </div>
            <div>
              <p className="text-[18px] text-right">
                <Skeleton
                  width={50}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </p>
              <p className="text-sm text-right">
                <Skeleton
                  width={30}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </p>
            </div>
            <div>
              <p className="text-[18px] text-right">
                <Skeleton
                  width={50}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </p>
              <p className="text-sm text-right">
                <Skeleton
                  width={30}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="h-52">
          <Skeleton
            height={200}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
      </div>
      <div className="grid gap-4 lg:gap-6 mt-6 lg:mt-10 lg:grid-cols-5">
        <div className="skeleton-background p-4 lg:col-span-2">
          <div className="text-[18px] leading-[23.4px] font-semibold">
            <Skeleton
              width={140}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </div>
          <div className="mt-6">
            <div className="mt-6 flex justify-center">
              <Skeleton
                height={190}
                width={190}
                borderRadius={100}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
            <div className="mt-6 flex justify-between">
              <div className="flex-row-2 justify-between">
                <p className="text-[14px] font-semibold flex justify-start">
                  <Skeleton
                    width={120}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <p className="font-medium text-[14px] leading-[15.6px] capitalize flex justify-start">
                  <Skeleton
                    width={125}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <p className="text-[14px] font-semibold flex justify-start">
                  <Skeleton
                    width={120}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <p className="font-medium text-[14px] leading-[15.6px] capitalize flex justify-start">
                  <Skeleton
                    width={90}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <p className="text-[14px] font-semibold flex justify-start">
                  <Skeleton
                    width={120}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <p className="font-medium text-[14px] leading-[15.6px] capitalize flex justify-start">
                  <Skeleton
                    width={70}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
              </div>
              <div className="flex-row-2 justify-between">
                <p className="text-[14px] font-semibold flex justify-start">
                  <Skeleton
                    width={120}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <p className="font-medium text-[14px] leading-[15.6px] capitalize flex justify-start">
                  <Skeleton
                    width={100}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <p className="text-[14px] font-semibold flex justify-start">
                  <Skeleton
                    width={120}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <p className="font-medium text-[14px] leading-[15.6px] capitalize flex justify-start">
                  <Skeleton
                    width={110}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="skeleton-background p-4 col-span-1 lg:col-span-3">
          <div className="mb-8 flex justify-between items-end">
            <div className="grid gap-1">
              <div className="text-[18px] leading-[23.4px] font-semibold">
                <Skeleton
                  width={170}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
              <div className="flex gap-1 ml-1 items-center text-xs font-medium">
                <Skeleton
                  width={50}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
            </div>
            <div>
              <p className="text-[18px] font-semibold text-right">
                <Skeleton
                  width={50}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </p>
              <p className="font-medium text-[12px] capitalize text-right">
                <Skeleton
                  width={70}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </p>
            </div>
          </div>
          <div>
            <Skeleton
              height={280}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ConversionOverview);
