import { useState, useEffect } from 'react';
import { svgIcons } from '../../components/svgIcons/svgIcons';
// import OffsetPaginator from '../../components/paginate/OffsetPaginator';
import { apiAgent } from '../../utils/apicall';
import { ProjectStatus } from '../../utils/constants';
import { Link } from 'react-router-dom';
import { styleGoogleCustomerId } from '../../utils/publicFunctions';
import Paginater from '../../components/paginate/Paginater';
import Skeleton from 'react-loading-skeleton';
import LinesEllipsis from 'react-lines-ellipsis';

const orderParams = [
  'name',
  'project__ads_status',
  'project__budget_plan',
  '',
  '',
  '',
];

export default function Properties() {
  // checkbox state
  const [checkboxList, setCheckboxList] = useState([]);

  // order
  const [ordering, setOrdering] = useState('-created_at');
  // filter
  const [pageIndex, setPageIndex] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const [filtering, setFiltering] = useState({
    project__ads_status__in: Object.values(ProjectStatus).join(','),
  });

  const [properties, setProperties] = useState([]);
  const [loadingFlag, setLoadingFlag] = useState(false);

  useEffect(() => {
    setLoadingFlag(true);
  }, [pageIndex, pageLimit, ordering, filtering]);

  useEffect(() => {
    if (!loadingFlag) return;
    (async () => {
      try {
        let params = {
          ordering,
          search,
          offset: pageIndex * pageLimit,
          limit: pageLimit,
          demo: true,
        };
        params = Object.assign(params, filtering);
        const response = await apiAgent.get({
          path: `/properties/`,
          params,
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        const responseResult = await response.json();
        setTotal(responseResult.count);
        setCheckboxList(Array(responseResult.results.length).fill(false));
        setProperties(responseResult.results);
        setLoadingFlag(false);
      } catch (error) {
        console.log(error);
        setLoadingFlag(false);
      }
    })();
  }, [loadingFlag]);

  useEffect(() => {
    document.title = 'Flair Dashboard';
  }, []);

  return (
    <>
      <h5 className="pt-14 mx-5 xs:pt-10 xs:mx-10 pb-6 mb-6 border-b border-b-[#6F728B21]">
        Properties
      </h5>
      {loadingFlag ? (
        <div className="skeleton-background xs:mx-10 grid grid-cols-1 mb-24">
          <div className="px-8 pt-8 pb-6 flex items-center justify-between gap-4">
            <div className="w-full xs:w-[376px] relative">
              <Skeleton
                height={46}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
                borderRadius={4}
              />
            </div>
            <div className="flex items-center gap-3">
              <p>
                <Skeleton
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  width={100}
                />
              </p>
              <Skeleton
                width={52}
                height={28}
                borderRadius={16}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
          </div>
          <div className="w-full overflow-x-hidden">
            <table className="border-collapse w-full min-w-fit relative">
              <thead>
                <tr className="bg-[#F2F5F966] border-t border-b border-[#E9ECF1]">
                  <th className="w-10 xs:w-16 px-3 xs:px-4 xs:pl-8 leading-3">
                    <Skeleton
                      width={16}
                      height={16}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </th>
                  {[64, 46, 49, 88, 73, 65].map((item, index) => (
                    <th key={index} className="last:xs:pr-4">
                      <div className="text-xs font-medium px-3 xs:px-4 py-3 w-full text-left">
                        <Skeleton
                          width={item}
                          baseColor="rgba(202, 220, 237, 0.7)"
                          highlightColor="rgba(219, 230, 242, 1)"
                        />
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {[...Array(10).keys()].map((row, i) => (
                  <tr
                    key={i}
                    className="even:bg-[#F2F5F966] border-t border-b border-[#E9ECF1]"
                  >
                    <td className="w-10 xs:w-16 px-3 xs:px-4 xs:pl-8 text-[14px] leading-3">
                      <Skeleton
                        width={16}
                        height={16}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </td>
                    <td className="w-[349px]">
                      <div className="px-3 xs:px-4 text-[14px] font-normal flex items-center gap-3">
                        <Skeleton
                          width={30}
                          height={30}
                          baseColor="rgba(202, 220, 237, 0.7)"
                          highlightColor="rgba(219, 230, 242, 1)"
                        />
                        <Skeleton
                          width={Math.random() * 100 + 50}
                          baseColor="rgba(202, 220, 237, 0.7)"
                          highlightColor="rgba(219, 230, 242, 1)"
                        />
                      </div>
                    </td>
                    <td className="px-3 xs:px-4 py-4 text-[14px] font-normal">
                      <Skeleton
                        width={102}
                        height={28}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </td>
                    <td className="px-3 xs:px-4 py-4 text-[14px] font-normal">
                      <Skeleton
                        width={50}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </td>
                    <td className="px-3 xs:px-4 py-4 text-[14px] font-normal">
                      <Skeleton
                        width={50}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </td>
                    <td className="px-3 xs:px-4 py-4 text-[14px] font-normal">
                      <Skeleton
                        width={50}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </td>
                    <td className="px-3 xs:px-4 py-4 text-[14px] font-normal">
                      <Skeleton
                        width={92}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mr-5 xs:mx-8 flex flex-col lg:flex-row justify-center mt-4 lg:mt-0 md:justify-between items-center">
            <p>
              <Skeleton
                width={253}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </p>
            <div className="pt-8 pb-12 flex flex-col gap-4 md:flex-row md:flex-wrap md:justify-end items-center">
              <div className="text-[14px] leading-[1.3] font-normal">
                <Skeleton
                  width={200}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
              <div className="flex items-center">
                <div className="md:pl-4 pr-4 md:border-l border-l-[#E9ECF1] font-medium text-[14px] leading-[18.2px]">
                  <Skeleton
                    width={71}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
                <Skeleton
                  width={40}
                  height={38}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <div className="px-4 border-r mr-4">
                  <Skeleton
                    width={37}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
                <Skeleton
                  width={59}
                  height={38}
                  borderRadius={4}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white shadow-[0_0_20.303px_37.7056px_rgba(183,188,200,0.11)] xs:mx-10 grid grid-cols-1 mb-24">
          <div className="px-8 pt-8 pb-6 flex items-center justify-between gap-4">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setPageIndex(0);
                setLoadingFlag(true);
              }}
              className="w-full xs:w-[376px] relative"
            >
              <input
                type="text"
                className="flair-search pl-10"
                placeholder="Search your properties here"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <div className="absolute top-0 left-3 h-full flex items-center">
                <div className="w-5 h-5 flex items-center justify-center">
                  <div className="w-[16.93px] h-[16.93px]">
                    {svgIcons.search}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="w-full xs:overflow-x-auto">
            <table className="border-collapse whitespace-nowrap w-full xs:min-w-fit relative">
              <thead>
                <tr className="bg-[#F2F5F966] border-t border-b border-[#E9ECF1]">
                  {['property', 'conversions', 'cost/conv', 'google id'].map(
                    (item, index) => (
                      <th
                        key={index}
                        className={`last:xs:pr-4 ${
                          ![0, 2].includes(index) && 'hidden'
                        } xs:table-cell`}
                      >
                        <button
                          onClick={() =>
                            ['property', 'status', 'budget'].includes(item) &&
                            setOrdering((prev) =>
                              prev.includes(orderParams[index])
                                ? orderParams[index] === prev
                                  ? `-${orderParams[index]}`
                                  : orderParams[index]
                                : orderParams[index],
                            )
                          }
                          className="uppercase text-[#707787] text-xs font-medium block px-3 xs:px-4 py-3 w-full text-left group"
                        >
                          {item}
                          <div
                            className={
                              ordering === `-${orderParams[index]}`
                                ? 'ml-2 inline-block w-1.5 align-middle pb-[3px] text-[#687BFE]'
                                : 'ml-2 inline-block w-1.5 align-middle pb-[3px] text-[#CED3DB] invisible group-hover:visible'
                            }
                          >
                            {svgIcons.up_vector}
                          </div>
                          <div
                            className={
                              ordering === `${orderParams[index]}`
                                ? 'inline-block w-1.5 align-middle pb-[3px] text-[#687BFE]'
                                : 'inline-block w-1.5 align-middle pb-[3px] text-[#CED3DB] invisible group-hover:visible'
                            }
                          >
                            {svgIcons.down_vector}
                          </div>
                        </button>
                      </th>
                    ),
                  )}
                </tr>
              </thead>
              <tbody>
                {properties.map((item, index) => (
                  <tr
                    className="even:bg-[#F2F5F966] hover:bg-[#687BFE14] border-t border-b border-[#E9ECF1]"
                    key={index}
                  >
                    <td
                      className={`w-[349px] border-l-4 xs:border-0 ${
                        [ProjectStatus.ACTIVE, ProjectStatus.PAUSING].includes(
                          item._ads_status,
                        )
                          ? 'border-[#38D086]'
                          : [
                              ProjectStatus.RESUMING,
                              ProjectStatus.PAUSED,
                            ].includes(item._ads_status)
                          ? 'border-[#F6D214]'
                          : 'border-[#FF7F5F]'
                      }`}
                    >
                      <Link
                        to={
                          ![
                            ProjectStatus.NONE,
                            ProjectStatus.CREATED,
                            ProjectStatus.CREATING,
                          ].includes(item._ads_status)
                            ? `/campaign/account-overview?project=${item._project_id}`
                            : `#`
                        }
                        className={`${
                          [
                            ProjectStatus.NONE,
                            ProjectStatus.CREATED,
                            ProjectStatus.CREATING,
                          ].includes(item._ads_status) && 'cursor-default'
                        } px-3 xs:px-4 text-[14px] font-normal flex items-center gap-3 truncate ...`}
                      >
                        <img
                          src={
                            item.highlight_photo
                              ? item.highlight_photo
                              : './assets/images/tile-noimg.svg'
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              './assets/images/tile-noimg.svg';
                          }}
                          className="w-[30px] h-[30px] object-cover"
                          alt="alter"
                        />
                        <LinesEllipsis
                          text={item.name}
                          component="span"
                          maxLine="1"
                          ellipsis="…"
                          trimRight
                        />
                      </Link>
                    </td>
                    <td className="px-3 xs:px-4 py-4 text-[14px] font-normal hidden xs:table-cell">
                      {item._conv <= 0
                        ? '-'
                        : Math.ceil(parseFloat(item._conv))}
                    </td>
                    <td className="px-3 xs:px-4 py-4 text-[14px] font-normal hidden xs:table-cell">
                      {item._conv <= 0 ? (
                        '-'
                      ) : (
                        <>$ {(item._cost / item._conv).toFixed(2)}</>
                      )}
                    </td>
                    <td className="px-3 xs:px-4 py-4 text-[14px] font-normal hidden xs:table-cell">
                      {item._gad_customer_id ? (
                        styleGoogleCustomerId(item._gad_customer_id)
                      ) : (
                        <>-</>
                      )}
                      {/* {item.gad_customer_id.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      '$1-$2-$3',
                    )} */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mr-5 xs:mx-8 flex flex-col lg:flex-row justify-center mt-4 lg:mt-0 md:justify-between items-center">
            <p className="whitespace-nowrap text-[#707787]">
              Displaying {checkboxList.length} properties out of {total} total
            </p>
            <Paginater
              total={total}
              pageIndex={pageIndex}
              pageLimit={pageLimit}
              onChangePageIndex={setPageIndex}
              onChangePageLimit={setPageLimit}
            />
            {/* <OffsetPaginator
              onSelectPage={({ offset: _offset, size: _size }) => {
                setOffset(_offset);
                setLimit(_size);
              }}
              total={total}
            /> */}
          </div>
        </div>
      )}
    </>
  );
}
