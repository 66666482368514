import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
  Menu,
  MenuItem,
} from 'react-pro-sidebar';
import { useLocation, Link } from 'react-router-dom';
import ConfirmModal from '../modalbootstrap/ConfirmModal';
import {
  fetchUnreadNotificationsCount,
  selectCount,
} from '../../features/notifications/unreadNotificationsSlice';
// import {
// fetchSearchPhrase,
// setSearchPhrase,
// } from '../../features/searchphrase/searchPhraseSlice';
// import { trim } from '../../utils/publicFunctions';
import { svgIcons } from '../svgIcons/svgIcons';
import { handleLogout } from '../../utils/publicFunctions';
import { Dropdown } from '@restart/ui';
import ReactTooltip from 'react-tooltip';

export default function Prosidebar(props) {
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const dispatch = useDispatch();

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [menuToggled, setMenuToggled] = useState(true);
  const [sidebarWidth, setSidebarWidth] = useState(
    window.innerWidth > 575 ? 88 : window.innerWidth,
  );

  const currentLocation = useLocation();
  const unreadCount = useSelector(selectCount);
  // const searchPhrase = useSelector(fetchSearchPhrase);
  const [modalShow, setModalShow] = useState(false);

  const closeToggle = () => {
    setMenuCollapse((prev) => !prev);
    setMenuToggled((prev) => !prev);
  };

  useEffect(closeToggle, [props]);

  useEffect(() => {
    dispatch(fetchUnreadNotificationsCount());
    const intervalID = setInterval(() => {
      dispatch(fetchUnreadNotificationsCount());
    }, 60_000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);
  window.addEventListener('resize', () => {
    setSidebarWidth(window.innerWidth > 575 ? 88 : window.innerWidth);
  });
  return (
    userInfo && (
      <div className="w-fit h-screen fixed top-0 z-50 xs:sticky xs:z-10">
        <ProSidebar
          collapsed={menuCollapse}
          breakPoint="sm"
          collapsedWidth={88}
          width={sidebarWidth}
          toggled={menuToggled}
        >
          <SidebarHeader>
            <div className="flex flex-row-reverse mt-6 mr-6 xs:hidden">
              <button className="w-3 " onClick={closeToggle}>
                {svgIcons.cross}
              </button>
            </div>
            <Menu>
              <MenuItem
                className="text-[18px] font-bold text-white leading-[23.4px]"
                icon={<div className="w-11">{svgIcons.logo}</div>}
              >
                Flair
              </MenuItem>
            </Menu>
          </SidebarHeader>
          <SidebarContent>
            <div className="mx-6 pt-12 pb-[10px] border-t border-t-[#515868] font-medium text-base xs:hidden">
              <div className="pl-2">MENU</div>
            </div>
            <Menu>
              {/* <MenuItem
              className="text-[18px] font-medium leading-[23.4px]"
              data-for="sidebar-tooltip"
              data-tip="Dashboard"
              active={currentLocation.pathname === '/dashboard'}
              icon={<div className="w-5">{svgIcons.dashboard}</div>}
            >
              Dashboard
              <Link to="/dashboard" onClick={closeToggle} />
            </MenuItem> */}

              <MenuItem
                className="text-[18px]"
                data-for="sidebar-tooltip"
                data-tip="Properties"
                active={currentLocation.pathname === '/properties'}
                icon={
                  <div className="relative">
                    <div className="w-5">{svgIcons.property}</div>
                  </div>
                }
              >
                Properties
                <Link to="/properties" onClick={closeToggle} />
              </MenuItem>
            </Menu>
          </SidebarContent>

          <SidebarFooter>
            <Dropdown>
              <Dropdown.Toggle>
                {(props) => (
                  <Link
                    to="#"
                    {...props}
                    className="flex justify-between items-center mx-5 pt-6 pb-[42px] sm:pb-10 border-t border-t-[#515868] xs:border-none"
                  >
                    <div className="flex items-center gap-5">
                      <div className="xs:w-[30px] w-[50px] xs:h-[30px] h-[50px] bg-[#687BFE] xs:bg-[#FF7F5F] text-white rounded-full text-center text-base xs:text-[14px] leading-[52px] xs:leading-[30px]">
                        {userInfo.photo ? (
                          <img
                            src={userInfo.photo}
                            className="w-full h-full rounded-full"
                          />
                        ) : userInfo.first_name ? (
                          userInfo.first_name[0]
                        ) : (
                          userInfo.name[0]
                        )}
                      </div>
                      <div className="grid gap-[2px] xs:hidden">
                        <div className="font-bold text-white text-base">
                          {userInfo.first_name
                            ? userInfo.first_name + ' ' + userInfo.last_name
                            : userInfo.name}
                        </div>
                        <div className="font-normal text-[14px] leading-[18.2px] text-[#707787]">
                          {userInfo ? userInfo.email : ''}
                        </div>
                      </div>
                    </div>
                    <div className="w-2 text-white hidden xs:block">
                      {props['aria-expanded']
                        ? svgIcons.dropup
                        : svgIcons.dropdown}
                    </div>
                    <div className="w-2 text-white xs:hidden">
                      {svgIcons.dropup}
                    </div>
                  </Link>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {(menuProps, meta) => (
                  <ul
                    {...menuProps}
                    className="fixed w-full xs:w-max bottom-0 xs:bottom-10 left-0 xs:left-20 bg-white border border-[#F2F5F9] shadow-[0_14px_26px_rgba(183, 188, 200, 0.11)] rounded p-6"
                    style={{
                      visibility: meta.show ? 'visible' : 'hidden',
                      opacity: meta.show ? '1' : '0',
                    }}
                  >
                    <Link
                      to="#"
                      onClick={() => meta.toggle()}
                      className="flex items-center justify-between"
                    >
                      <div className="flex items-center gap-4 xs:gap-2">
                        <div className="xs:w-6 w-[50px] text-white xs:h-6 h-[50px] bg-[#687BFE] xs:bg-[#FF7F5F] rounded-full text-center leading-[50px] xs:leading-[26px] font-bold xs:font-semibold text-base xs:text-[12px]">
                          {userInfo.photo ? (
                            <img
                              src={userInfo.photo}
                              className="w-full h-full rounded-full"
                            />
                          ) : userInfo.first_name ? (
                            userInfo.first_name[0]
                          ) : (
                            userInfo.name[0]
                          )}
                        </div>
                        <div className="text-[#707787]">
                          <div className="font-bold xs:font-semibold text-base xs:text-[14px] leading-[20.8px] xs:leading-[15.4px] xs:text-[#161D37]">
                            {userInfo.first_name
                              ? userInfo.first_name + ' ' + userInfo.last_name
                              : userInfo.name}
                          </div>
                          <div className="font-normal text-[14px] leading-[18.2px] xs:hidden">
                            {userInfo ? userInfo.email : ''}
                          </div>
                        </div>
                      </div>

                      <div className="xs:hidden w-2 text-[#707787]">
                        {svgIcons.dropdown}
                      </div>
                    </Link>
                    <Link
                      to="profile"
                      onClick={() => {
                        meta.toggle();
                        closeToggle();
                      }}
                      className="text-[#707787] font-medium xs:font-semibold text-[18px] xs:text-[14px] leading-[23.4px] xs:leading-[15.4px] flex items-center gap-[18px] xs:gap-[10px] py-5 xs:py-[15px] mt-5 xs:mt-[10px]"
                    >
                      <div className="w-[15px] ml-[3px] mr-[2px] xs:w-3">
                        {svgIcons.profile}
                      </div>
                      Profile
                    </Link>
                    {/* <Link
                      to="settings"
                      onClick={() => {
                        meta.toggle();
                        closeToggle();
                      }}
                      className="text-[#707787] font-medium xs:font-semibold text-[18px] xs:text-[14px] leading-[23.4px] xs:leading-[15.4px] flex items-center gap-[18px] xs:gap-[10px] py-5 xs:py-[15px]"
                    >
                      <div className="w-[21px] xs:w-[18px]">
                        {svgIcons.setting}
                      </div>
                      My Settings
                    </Link> */}
                    {/* <Link
                      to="#"
                      onClick={() => {
                        meta.toggle();
                        closeToggle();
                        window.location.href = 'https://help.getflair.io/en/';
                      }}
                      className="text-[#707787] font-medium xs:font-semibold text-[18px] xs:text-[14px] leading-[23.4px] xs:leading-[15.4px] flex items-center gap-[18px] xs:gap-[10px] py-5 xs:py-[15px]"
                    >
                      <div className="w-5 xs:w-[16.67px]">{svgIcons.help}</div>
                      Help
                    </Link> */}
                    <a
                      href="mailto:hello@getflair.io"
                      onClick={() => {
                        meta.toggle();
                        closeToggle();
                      }}
                      className="text-[#707787] font-medium xs:font-semibold text-[18px] xs:text-[14px] leading-[23.4px] xs:leading-[15.4px] flex items-center gap-[18px] xs:gap-[10px] py-5 xs:py-[15px]"
                    >
                      <div className="w-5 xs:w-[16.67px]">{svgIcons.help}</div>
                      Help
                    </a>
                    <Link
                      to="#"
                      onClick={() => {
                        meta.toggle();
                        closeToggle();
                        setModalShow(true);
                      }}
                      className="text-[#707787] font-medium xs:font-semibold text-[18px] xs:text-[14px] leading-[23.4px] xs:leading-[15.4px] flex items-center gap-[18px] xs:gap-[10px] py-5 xs:py-[15px] xs:pb-0"
                    >
                      <div className="w-[22px] xs:w-[18.33px]">
                        {svgIcons.exit}
                      </div>
                      Sign out
                    </Link>
                  </ul>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </SidebarFooter>
          <ReactTooltip
            id="sidebar-tooltip"
            place="right"
            type="light"
            effect="solid"
          />
          <ConfirmModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            onClose={() => setModalShow(false)}
            mark={{
              icon: svgIcons.logout,
              parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
              childClass: 'w-[32px] h-[32px]',
            }}
            title="Log out"
            txt="Are you sure want to log out?"
            handleConfirm={handleLogout}
          />
        </ProSidebar>
      </div>
    )
  );
}
