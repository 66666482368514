import { svgIcons } from '../svgIcons/svgIcons';

export function Navbar(props) {
  return (
    <div className="xs:hidden bg-[#161D37] text-white py-4 px-5 flex justify-between items-center sticky top-0 z-40">
      <div className="w-9">{svgIcons.logo}</div>
      <div className="w-full relative">
        <input
          className="input-basic bg-transparent py-3 px-12 w-full leading-4"
          placeholder="Search"
        />
        <div className="absolute top-0 left-[21px] h-full flex items-center">
          <div className="w-[17px] h-[17px]">{svgIcons.search}</div>
        </div>
      </div>
      <button className="w-5 h-4" onClick={() => props.toggle((prev) => !prev)}>
        {svgIcons.hamburger}
      </button>
    </div>
  );
}
