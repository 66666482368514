import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
//redux
import { Provider } from 'react-redux';
import { store } from './app/store';
//pages
import Home from './pages/Home';
import Campaign from './pages/Project/Campaign';
import Properties from './pages/Properties/Properties';
import Dashboard from './pages/Dashboard/Dashboard';
import Project from './pages/Project/Project';
import Profile from './pages/Profile/Profile';
import Report from './pages/Reports/Report';
import AccountOverview from './pages/Reports/AccountOverview';
import BudgetOverview from './pages/Reports/BudgetOverview';
import ConversionOverview from './pages/Reports/ConversionOverview';
import KeywordPerformance from './pages/Reports/KeywordPerformance';
import DemographicPerformance from './pages/Reports/DemographicPerformance';
import SearchTermOverview from './pages/Reports/SearchTermOverview';
import DSASearchTermOverview from './pages/Reports/DSASearchTermOverview';
import BiddingStrategyOverview from './pages/Reports/BiddingStrategyOverview';
import AdGroupAdOverview from './pages/Reports/AdGroupAdOverview';

//styles
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import './tailwind.css';
import './flair.scss';
import './assets/styles/flair/form.scss';

// custom dialogs
import AlertDialog from './components/dialogs/alertdialog';
import ConfirmDialog from './components/dialogs/confirmdialog';

import Extra from './pages/Extra';

import Loading from './components/loading/Loading';
import S from './pages/Reports/S';
import SLink from './pages/Reports/SLink';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Extra />} />
          <Route path="" element={<Extra />} />
          <Route path="share/:token" element={<SLink />} />
          <Route path="share/:token" element={<S />}>
            <Route path="account-overview" element={<AccountOverview />} />
            <Route path="budget-overview" element={<BudgetOverview />} />
            <Route
              path="conversion-overview"
              element={<ConversionOverview />}
            />
            <Route
              path="keyword-performance"
              element={<KeywordPerformance />}
            />
            <Route
              path="demographic-performance"
              element={<DemographicPerformance />}
            />
          </Route>
          <Route path="s" element={<Report />}>
            <Route path="account-overview" element={<AccountOverview />} />
            <Route path="budget-overview" element={<BudgetOverview />} />
            <Route
              path="conversion-overview"
              element={<ConversionOverview />}
            />
            <Route
              path="keyword-performance"
              element={<KeywordPerformance />}
            />
            <Route
              path="demographic-performance"
              element={<DemographicPerformance />}
            />
          </Route>
          <Route path="" element={<Home />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="properties" element={<Properties />} />
            {/* <Route path="properties/add" element={<AddProperty />} />
            <Route path="properties/edit/:id" element={<EditProperty />} />
            <Route path="properties/preview/:id" element={<Preview />} /> */}
            {/* <Route path="project/:id" element={<Project />} /> */}
            <Route path="campaign" element={<Campaign />} />
            <Route path="profile" element={<Profile />} />
            {/* <Route path="report" element={<Report />}>
              <Route path="account-overview" element={<AccountOverview />} />
              <Route path="budget-overview" element={<BudgetOverview />} />
              <Route
                path="conversion-overview"
                element={<ConversionOverview />}
              />
              <Route
                path="keyword-performance"
                element={<KeywordPerformance />}
              />
              <Route
                path="demographic-performance"
                element={<DemographicPerformance />}
              />
            </Route> */}
            <Route path="campaign" element={<Campaign />}>
              <Route path="account-overview" element={<AccountOverview />} />
              <Route path="budget-overview" element={<BudgetOverview />} />
              <Route
                path="conversion-overview"
                element={<ConversionOverview />}
              />
              <Route
                path="keyword-performance"
                element={<KeywordPerformance />}
              />
              <Route
                path="demographic-performance"
                element={<DemographicPerformance />}
              />
              <Route
                path="search-term-overview"
                element={<SearchTermOverview />}
              />
              <Route
                path="DSA-search-term-overview"
                element={<DSASearchTermOverview />}
              />
              <Route
                path="bidding-strategy-overview"
                element={<BiddingStrategyOverview />}
              />
              <Route
                path="ad-group-ad-overview"
                element={<AdGroupAdOverview />}
              />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      <AlertDialog />
      <ConfirmDialog />
      <Loading />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
