import React, { useState, useEffect, memo } from 'react';
import { useOutletContext } from 'react-router-dom';

import Select, { components } from 'react-select';
import ReactTooltip from 'react-tooltip';
import Skeleton from 'react-loading-skeleton';

import { apiAgent } from '../../utils/apicall';
import OffsetPaginator from '../../components/paginate/OffsetPaginator';
import { svgIcons } from '../../components/svgIcons/svgIcons';

const SearchTermOverview = () => {
  const [project, period] = useOutletContext();

  const [responseLists, setResponseLists] = useState({});

  const [campaignList, setCampaignList] = useState([]);
  const [adGroupList, setAdGroupList] = useState([]);

  const [campaign, setCampaign] = useState({ value: null, label: null });
  const [adGroup, setAdGroup] = useState({ value: null, label: null });
  const [device, setDevice] = useState({ value: 'All', label: 'All Devices' });

  const [dataPerformance, setDataPerformance] = useState([]);

  // pagination
  const [total, setTotal] = useState(0);
  const [tableOffset, setTableOffset] = useState(0);
  const [tableRowCount, setTableRowCount] = useState(10);
  const [tableSort, setTableSort] = useState({ key: null, direction: true });

  const [infoLoaded, setInfoLoaded] = useState(false);

  useEffect(() => {
    if (project) fetchList();
  }, [project]);
  const fetchList = async () => {
    try {
      setInfoLoaded(false);
      var path = '/projects/';
      if (
        window.location.href.search('/s/') >= 0 ||
        window.location.href.search('/share/') >= 0
      )
        path = '/reports/';
      const response = await apiAgent.get({
        path: path + `${project}/get_gadp_campaigns_adgroups/`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const lists = await response.json();
      setResponseLists(lists);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      responseLists.hasOwnProperty('campaignList') &&
      responseLists.hasOwnProperty('adGroupList')
    )
      setCampaignList(
        [{ value: 'All', label: 'All Campaigns' }].concat(
          responseLists.campaignList,
        ),
      );
  }, [responseLists]);

  useEffect(() => {
    if (campaignList.length)
      setCampaign({ value: 'All', label: 'All Campaigns' });
  }, [campaignList]);

  useEffect(() => {
    if (!campaign.value) return;
    if (campaign.value === 'All')
      setAdGroupList(
        [{ value: 'All', label: 'All Ad Groups' }].concat(
          responseLists.adGroupList,
        ),
      );
    else
      setAdGroupList(
        [{ value: 'All', label: 'All Ad Groups' }].concat(
          responseLists.adGroupList.filter(
            (item) => item.campaign === campaign.value,
          ),
        ),
      );
  }, [campaign]);

  useEffect(() => {
    if (adGroupList.length)
      setAdGroup({ value: 'All', label: 'All Ad Groups' });
  }, [adGroupList]);

  useEffect(() => {
    if (period.length && adGroup.value && device.value) fetchOverview();
  }, [period, device, adGroup]);

  useEffect(() => {
    if (tableSort.key)
      setDataPerformance((prev) => {
        let temp = Array.from(prev);
        temp.sort((a, b) => {
          let booleanResult;
          if (a[tableSort.key] === null) booleanResult = -1;
          else if (b[tableSort.key] === null) booleanResult = 1;
          else if (
            Number.isFinite(a[tableSort.key]) ||
            Number.isFinite(b[tableSort.key])
          ) {
            booleanResult = a[tableSort.key] - b[tableSort.key];
          } else {
            booleanResult =
              a[tableSort.key].toUpperCase() < b[tableSort.key].toUpperCase()
                ? -1
                : 1;
          }
          return tableSort.direction ? booleanResult : -1 * booleanResult;
        });
        return temp;
      });
  }, [tableSort]);

  const fetchOverview = async () => {
    try {
      setInfoLoaded(false);
      var path = '/projects/';
      if (
        window.location.href.search('/s/') >= 0 ||
        window.location.href.search('/share/') >= 0
      )
        path = '/reports/';
      const responseKeyword = await apiAgent.get({
        path: path + `${project}/search_term_overview/`,
        params: {
          period,
          campaign: campaign.value,
          adGroup: adGroup.value,
          device: device.value,
        },
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const data = await responseKeyword.json();

      setDataPerformance(() =>
        data.search_term_view.map((item) => {
          return {
            searchTerm: item.searchTermView.searchTerm,
            searchTermMatchType: item.segments.searchTermMatchType,
            clicks: item.metrics.clicks / 1 || 0,
            impressions: item.metrics.impressions / 1 || 0,
            cost: item.metrics.costMicros / 1000000 || 0,
          };
        }),
      );
      setTotal(data.search_term_view.length);
      setInfoLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  return project && infoLoaded ? (
    <div className="my-6">
      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {/* <div className="">
          <Select
            placeholder="Account"
            styles={customStyles}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            clearable={true}
          />
        </div> */}
        <div className="">
          <Select
            options={campaignList}
            onChange={setCampaign}
            placeholder="Campaign"
            styles={customStyles}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            value={campaign}
            clearable={true}
          />
        </div>
        <div className="">
          <Select
            options={adGroupList}
            onChange={setAdGroup}
            value={adGroup}
            placeholder="Ad Group"
            styles={customStyles}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            clearable={true}
          />
        </div>
        <div className="">
          <Select
            options={[
              { value: 'All', label: 'All Devices' },
              { value: 'CONNECTED_TV', label: 'CONNECTED_TV' },
              { value: 'DESKTOP', label: 'DESKTOP' },
              { value: 'MOBILE', label: 'MOBILE' },
              { value: 'OTHER', label: 'OTHER' },
              { value: 'TABLET', label: 'TABLET' },
              { value: 'UNKNOWN', label: 'UNKNOWN' },
            ]}
            onChange={setDevice}
            value={device}
            placeholder="Device"
            styles={customStyles}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            clearable={true}
          />
        </div>
      </div>
      <div id="search-term-performance">
        <div className="grid grid-cols-1 border border-[#E9ECF1] rounded bg-white mt-6 xs:mt-10 py-4">
          <div className=" text-[18px] leading-[23.4px] font-semibold px-4">
            Search term overview
          </div>
          <div className="overflow-auto">
            <table className="w-full mt-6">
              <thead>
                <tr className="text-[#707787] bg-[#F2F5F966] border-b-[#E9ECF1] border-b">
                  {Object.entries({
                    searchTerm: 'search term',
                    searchTermMatchType: 'search term match type',
                    clicks: 'clicks',
                    impressions: 'impressions',
                    cost: 'cost',
                  }).map((item, idx) => (
                    <th key={idx}>
                      <button
                        className="text-xs font-medium text-left uppercase px-2 py-6 flex gap-2 justify-between items-center"
                        onClick={() =>
                          setTableSort((prev) => {
                            return {
                              key: item[0],
                              direction:
                                prev.key !== item[0] ? true : !prev.direction,
                            };
                          })
                        }
                      >
                        <div>{item[1]}</div>
                        <div className="w-2">
                          {tableSort.key === item[0] &&
                            (tableSort.direction
                              ? svgIcons.dropup
                              : svgIcons.dropdown)}
                        </div>
                      </button>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataPerformance
                  .slice(tableOffset, tableOffset + tableRowCount)
                  .map((item, idx) => (
                    <tr key={idx} className="border-b-[#E9ECF1] border-b">
                      <td className="text-[14px] font-medium px-2 py-4">
                        {item.searchTerm}
                      </td>
                      <td className="text-[14px] font-medium px-2 py-4">
                        {item.searchTermMatchType}
                      </td>
                      <td className="text-[14px] font-medium px-2 py-4">
                        {item.clicks.toLocaleString()}
                      </td>
                      <td className="text-[14px] font-medium px-2 py-4">
                        {item.impressions.toLocaleString()}
                      </td>
                      <td className="text-[14px] font-medium px-2 py-4">
                        ${parseFloat(item.cost.toFixed(2)).toLocaleString()}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="px-12" id="keyword-performance-pag">
            <OffsetPaginator
              onSelectPage={({ offset: _offset, size: _size }) => {
                setTableOffset(_offset);
                setTableRowCount(_size);
              }}
              total={total}
            />
          </div>
        </div>
      </div>
      <ReactTooltip
        id="chart-info"
        place="right"
        type="light"
        effect="solid"
        multiline={true}
        className="w-40"
      />
    </div>
  ) : (
    <div className="my-6">
      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {/* <div className="">
          <Skeleton
            height={38}
            borderRadius={2}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div> */}
        <div className="">
          <Skeleton
            height={38}
            borderRadius={2}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
        <div className="">
          <Skeleton
            height={38}
            borderRadius={2}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
        <div className="">
          <Skeleton
            height={38}
            borderRadius={2}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
      </div>
      <div className="skeleton-background mt-6 xs:mt-10 overflow-hidden py-4 grid grid-cols-1">
        <Skeleton
          width={280}
          borderRadius={2}
          baseColor="rgba(202, 220, 237, 0.7)"
          highlightColor="rgba(219, 230, 242, 1)"
          containerClassName="px-4"
        />
        <table className="w-full">
          <thead>
            <tr>
              {Array.from(Array(9).keys()).map((tdElement) => (
                <th
                  key={tdElement}
                  className="px-2 py-6 text-xs border-b-[#E9ECF1] border-b"
                >
                  <Skeleton
                    width={70}
                    borderRadius={2}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from(Array(10).keys()).map((trElement) => (
              <tr key={trElement} className="">
                {Array.from(Array(9).keys()).map((tdElement) => (
                  <td
                    key={tdElement}
                    className="py-4 px-2 w-12 text-xs border-b-[#E9ECF1] border-b"
                  >
                    <Skeleton
                      width={60}
                      borderRadius={2}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const customStyles = {
  control: (styles) => ({
    ...styles,
    border: '1px solid #E9ECF1',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
  }),
  DropdownIndicator: (base) => ({
    ...base,
  }),
};
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props} className="pr-4 w-9 text-black">
      {svgIcons.down_arrow}
    </components.DropdownIndicator>
  );
};

export default memo(SearchTermOverview);
