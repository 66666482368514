import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { DateTime } from 'luxon';
import { Dropdown, Button } from '@restart/ui';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import LinesEllipsis from 'react-lines-ellipsis';
import { filter, get, isNumber } from 'lodash-es';

import config from '../../utils/config';
import ConfirmModal from '../../components/modalbootstrap/ConfirmModal';
import InputModal from '../../components/modalbootstrap/InputModal';
import SuccessModal from '../../components/modalbootstrap/SuccessModal';
import BarChart from '../../components/charts/BarChart';
import LineChart from '../../components/charts/LineChart';
import ProjectTile from '../../components/tile/ProjectTile';
import DayPickerBody from '../../components/daypicker/DayPickerBody';
import { delay } from '../../utils/promises';
import {
  apiAgent,
  APIMessage,
  HTTP_STATUS,
  RedirBehavior,
  RetryBehavior,
  APIAgentError,
  ServerDeclineError,
  retriableErrors,
  redirableErrors,
  InternalServerError,
  CommunicationError,
} from '../../utils/apicall';
import {
  monthlyBudget,
  projectStatusColor,
  projectStatusName,
} from '../../utils/project';
import {
  convertNumberType,
  styleGoogleCustomerId,
} from '../../utils/publicFunctions';
import { formatPeriod } from '../../utils/times';
import { ChartDiff } from '../../utils/chartDiff';
import uxQueue from '../../utils/uxqueue';
import ProjectSkeleton from '../../components/skeleton/ProjectSkeleton';
import uxDialog from '../../utils/uxdialog';
import { svgIcons } from '../../components/svgIcons/svgIcons';

const todayDate = moment();
const last7Date = moment().subtract(6, 'days');
const defaultFrom = DateTime.fromJSDate(last7Date._d);
const defaultTo = DateTime.fromJSDate(todayDate._d);

export default function Project() {
  const NODATA = 'N/A';
  const params = useParams();
  const projectId = params.id;

  const [project, setProject] = useState(null);
  const [modal, setModal] = useState(null);
  const [pickerTitle, setPickerTitle] = useState(
    formatPeriod(defaultFrom, defaultTo),
  );

  // budget-section
  const [spends, setSpends] = useState([]);
  const [spendsSetLabel, setSpendsSetLabel] = useState('');
  const [spendLabels, setSpendLabels] = useState([]);
  const [spendsSum, setSpendsSum] = useState(null);

  // stats-section
  const [statSetLabels, setStatSetLabels] = useState([]);
  const [statLabels, setStatLabels] = useState([]);
  const [statSums, setStatSums] = useState({});
  const [statDataSets, setStatDataSets] = useState({});

  const [projectLoaded, setProjectLoaded] = useState(false);
  const [spendsLoaded, setSpendsLoaded] = useState(false);

  const fetchProjectData = async () => {
    try {
      setProjectLoaded(false);
      const response = await apiAgent.get({
        path: `/projects/${projectId}`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const projectBody = await response.json();
      // console.log(projectBody);
      setProject(projectBody);
      window.document.title = `Campaigns for ${projectBody.property.name}`;

      setProjectLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSpend = async () => {
    const dtToday = DateTime.now().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const dtFirstDay = dtToday.set({ day: 1 });
    const params = {};
    if (dtToday.ts === dtFirstDay.ts) {
      params.segmenting = 'hour';
      params.period = dtToday.toFormat('yyyy-LL-dd');
    } else {
      params.segmenting = 'date';
      params['period[min]'] = dtFirstDay.toFormat('yyyy-LL-dd');
      params['period[max]'] = dtToday.toFormat('yyyy-LL-dd');
    }
    handleSelectedPeriods({
      from: last7Date._d,
      to: todayDate._d,
      fromRight: null,
      toRight: null,
    });

    try {
      setSpendsLoaded(false);
      const response = await apiAgent.get({
        path: `projects/${projectId}/performance_stats`,
        params,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const body = await response.json();
      const bodyStats = padStatsRecords(
        body.stats,
        getSegmentsForPeriod(dtFirstDay, dtToday),
      );
      const _labels = bodyStats.map((item) => segmentsToLabel(item.segments));
      let _spends = bodyStats.map((item) => item.metrics.costMicros);
      const _spends_sum = parseFloat(
        (_spends.reduce((sum, item) => sum + item, 0) / 1000000).toFixed(2),
      );
      _spends = _spends.map((spend) =>
        parseFloat((spend / 1000000).toFixed(2)),
      );
      setSpendLabels(_labels);
      setSpends(_spends);
      setSpendsSum(_spends_sum);
      setSpendsSetLabel(formatPeriod(dtFirstDay, dtToday));
      setSpendsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProjectData();
    fetchSpend();
  }, []);

  // UI handlers
  const handleConfirmBudget = async (userInput) => {
    // TODO: show some progress HUD
    if (parseInt(userInput).toString() !== userInput) {
      await uxDialog.alert({
        text: `Your monthly budget needs to be a whole number (i.e. "$2000"). Please enter a new budget.`,
      });
      return;
    }
    const dailyBudget = (parseInt(userInput) / 30.4).toFixed(2);
    const monthlyBudget = Math.round(dailyBudget * 30.4);
    if (
      !(await uxDialog.confirm({
        text: `Your community will now spend $${monthlyBudget.toLocaleString()} over the next 30 days. Click OK to confirm.`,
      }))
    ) {
      return;
    }
    setModal(null);
    try {
      const response = await apiAgent.patch({
        path: `projects/${project.id}`,
        data: {
          budget_plan: dailyBudget,
        },
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const body = await response.json();
      if (body.budget_plan) {
        setProject((prev) => ({ ...prev, budget_plan: body.budget_plan }));
      }
      setModal('UpdatedBudget');
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmDisable = async () => {
    setModal(null);
    setTimeout(function () {
      setModal('Pending');
    }, 349);
  };

  const handleConfirmPause = async () => {
    // TODO: show some progress HUD
    setModal(null);
    try {
      const response = await apiAgent.post({
        path: `projects/${project.id}/pause`,
        data: {},
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const body = await response.json();
      setProject(body);
      if (parseInt(response.status) === parseInt(HTTP_STATUS.OK))
        setModal('Paused');
      else {
        setModal('ServerAcceptedRequest');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmActivate = async () => {
    // TODO: show some progress HUD
    setModal(null);
    try {
      const response = await apiAgent.post({
        path: `projects/${project.id}/activate`,
        data: {},
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const body = await response.json();
      setProject(body);
      if (parseInt(response.status) === parseInt(HTTP_STATUS.OK))
        setModal('Activated');
      else {
        setModal('ServerAcceptedRequest');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dd = (number) => {
    return number < 10 ? '0' + number : '' + number;
  };

  const segmentsToLabel = (segmentDict) => {
    if (isNumber(segmentDict.hour)) {
      return dd(segmentDict.hour + ':00');
    } else if (segmentDict.date) {
      const today = new Date();
      if (segmentDict.date.startsWith(`${today.getFullYear()}-`)) {
        return segmentDict.date.substr(5);
      }
      return segmentDict.date;
    } else {
      // TODO: impossible to reach here, report
      return JSON.toString(segmentDict);
    }
  };

  const getSegmentFromDict = (segmentDict) => {
    if (segmentDict.hour) {
      return segmentDict.hour;
    } else if (segmentDict.date) {
      return segmentDict.date;
    } else {
      // TODO: impossible to reach here, report
      return JSON.toString(segmentDict);
    }
  };

  const getSegmentsForPeriod = (dtFrom, dtTo) => {
    if (dtFrom.ts === dtTo.ts) {
      const hoursegments = [];
      for (let i = 0; i < 24; i++) {
        hoursegments.push({ hour: i });
      }
      return hoursegments;
    } else {
      const datesegments = [];
      for (
        let dtIter = dtFrom;
        !(dtIter > dtTo);
        dtIter = dtIter.plus({ days: 1 })
      ) {
        datesegments.push({
          date: dtIter.toFormat('yyyy-LL-dd'),
        });
      }
      return datesegments;
    }
  };

  const padStatsRecords = (stats, segments) => {
    const paddedStats = [];
    for (const segment of segments) {
      const item = stats.find(
        (stat) =>
          getSegmentFromDict(stat.segments) === getSegmentFromDict(segment),
      );
      if (item) {
        paddedStats.push(item);
      } else {
        paddedStats.push({
          metrics: {
            clicks: 0,
            conversions: 0,
            costMicros: 0,
            impressions: 0,
            interactions: 0,
            averageCpc: null,
          },
          segments: segment,
        });
      }
    }
    return paddedStats;
  };

  const convertDateTime = (dateObj) => {
    if (!dateObj) return dateObj;
    return DateTime.fromJSDate(dateObj).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  };

  const handleSelectedPeriods = async (data) => {
    let { from, to, fromRight, toRight } = data;

    let periods = [
      [convertDateTime(from), convertDateTime(to)],
      [convertDateTime(fromRight), convertDateTime(toRight)],
    ];

    // Trim periods
    periods = filter(periods, (period) => period[0] || period[1]);
    if (periods.length < 1) {
      throw 'Please select period.';
    }

    // Set null values with counterpart
    for (const period of periods) {
      if (!period[0]) period[0] = period[1];
      if (!period[1]) period[1] = period[0];
    }

    // Compare duration if two periods
    if (periods.length === 2) {
      if (
        periods[0][0].diff(periods[0][1]).values.milliseconds !==
        periods[1][0].diff(periods[1][1]).values.milliseconds
      ) {
        throw "Two periods of different duration can't be compared.";
      }
    }

    let queryparams = [];
    let strPeriods = [];
    let segmenting;
    for (const period of periods) {
      const dtFrom = period[0];
      const dtTo = period[1];
      strPeriods.push(formatPeriod(dtFrom, dtTo));

      if (dtFrom.ts === dtTo.ts) {
        segmenting = 'hour';
        queryparams.push({
          segmenting,
          period: dtFrom.toFormat('yyyy-LL-dd'),
        });
      } else {
        segmenting = 'date';
        const _params = { segmenting };
        _params['period[min]'] = dtFrom.toFormat('yyyy-LL-dd');
        _params['period[max]'] = dtTo.toFormat('yyyy-LL-dd');
        queryparams.push(_params);
      }
    }

    if (queryparams.length === 0) {
      throw 'Period is not determined.'; // This line will never reach.
    }

    const requests = queryparams.map((_params) =>
      apiAgent.get({
        path: `projects/${projectId}/performance_stats`,
        params: _params,
        options: {
          retryBehavior: RetryBehavior.NoRetry,
          redirBehavior: RedirBehavior.NoRedir,
        },
      }),
    );

    try {
      const responses = await Promise.all(requests);

      let errorResponse = null;
      for (const response of responses) {
        if (!response.ok) {
          errorResponse = response;
          break;
        }
      }
      if (errorResponse) {
        if (
          await uxDialog.confirm({
            text: await APIMessage.declinemessage({
              response: errorResponse,
              confirm: true,
            }),
          })
        ) {
          return handleSelectedPeriods({ from, to, fromRight, toRight });
        }
      } else {
        const setlabels = [];
        let labels = [];
        const datasets = {
          clicks: [],
          impressions: [],
          conversions: [],
          conversionRates: [],
          CPC: [],
          spends: [],
        };
        const sums = {
          clicks: [],
          impressions: [],
          conversions: [],
          conversionRates: [],
          CPC: [],
          spends: [],
        };

        for (let idx = 0; idx < strPeriods.length; idx++) {
          setlabels.push(strPeriods[idx]);
          const response = responses[idx];
          const body = await response.json();
          const bodyStats = padStatsRecords(
            body.stats,
            getSegmentsForPeriod(periods[idx][0], periods[idx][1]),
          );

          const _labels = bodyStats.map((item) =>
            segmentsToLabel(item.segments),
          );
          if (parseInt(idx) === 0) {
            labels = _labels;
          } else {
            for (let i = 0; i < labels.length; i++) {
              if (segmenting.toString() === 'date')
                labels[i] = `${labels[i]} (${_labels[i]})`;
            }
          }

          const _clicks = bodyStats.map((item) => item.metrics.clicks);
          const _clicks_sum = _clicks.reduce((sum, item) => sum + item, 0);
          datasets['clicks'].push(_clicks);
          sums['clicks'].push(_clicks_sum);

          const _impressions = bodyStats.map(
            (item) => item.metrics.impressions,
          );
          const _impressions_sum = _impressions.reduce(
            (sum, item) => sum + item,
            0,
          );
          datasets['impressions'].push(_impressions);
          sums['impressions'].push(_impressions_sum);

          const _conversions = bodyStats.map(
            (item) => item.metrics.conversions,
          );
          const _conversions_sum = _conversions.reduce(
            (sum, item) => sum + item,
            0,
          );
          datasets['conversions'].push(_conversions);
          sums['conversions'].push(_conversions_sum);

          let _spends = bodyStats.map((item) => item.metrics.costMicros);
          const _spends_sum = parseFloat(
            (_spends.reduce((sum, item) => sum + item, 0) / 1000000).toFixed(2),
          );
          _spends = _spends.map((spend) =>
            parseFloat((spend / 1000000).toFixed(2)),
          );
          datasets['spends'].push(_spends);
          sums['spends'].push(_spends_sum);

          const _conversionRates = bodyStats.map((item) =>
            item.metrics.interactions > 0
              ? item.metrics.conversions / item.metrics.interactions
              : 0,
          );
          const _totalInteractions = bodyStats.reduce(
            (sum, item) => sum + item.metrics.interactions,
            0,
          );
          const _totalConversions = bodyStats.reduce(
            (sum, item) => sum + item.metrics.conversions,
            0,
          );
          const _avgConversionRates =
            _totalInteractions > 0
              ? (_totalConversions / _totalInteractions) * 100
              : null;
          datasets['conversionRates'].push(_conversionRates);
          sums['conversionRates'].push(_avgConversionRates);

          const _totalCostMicros = bodyStats.reduce(
            (sum, item) => sum + item.metrics.costMicros,
            0,
          );
          const _totalClicks = bodyStats.reduce(
            (sum, item) => sum + item.metrics.clicks,
            0,
          );
          const _cpc = bodyStats.map(
            (item) => item.metrics.averageCpc / 1000000,
          );
          const _avgCpc =
            _totalClicks > 0
              ? parseFloat(
                  (_totalCostMicros / _totalClicks / 1000000).toFixed(2),
                )
              : null;
          datasets['CPC'].push(_cpc);
          sums['CPC'].push(_avgCpc);
        }
        setStatLabels(labels);
        setStatSetLabels(setlabels);
        setStatDataSets(datasets);
        setStatSums(sums);
        setPickerTitle(setlabels.join(' to '));

        // Data Structure
        // label of each period
        // setlabels[0] = 'Jan 10 - Feb 10, 2021' | 'Feb 2'

        // segments in technical form.
        // segments[0..1] = ['2022-01-10', '2022-01-11', ...] | [0,1,2,...,23],

        // labels=['Jan 10 (Feb 10)', ...] | ['00:00','01:00', ...,'23:00',]
        // datasets['clicks'][0..1] = [2,5,100,24,21,...] // [Int], straight
        // sums['clicks'][0..1]
        // datasets['impressions'][0..1] = [23, 234, ] // [Int], straight
        // datasets['impressions'][0..1]
        // datasets['conversions'][0..1] = [2.33,] // [Double], straight
        // datasets['conversionRatesStrict'][0..1] = [40.22,10.11,44.00] // [Percent], formula : (interactions > 0 ? conversions/interactions : null)
        // datasets['conversionRates'][0..1] = [40.22,10.11,44.00] // [Percent], formula : (interactions > 0 ? conversions/interactions : 0)
        // datasets['CPC'][0..1] = [2.33] // [Decimal.2], formula (clicks ? costMicros/clicks/1000000 : 0).toDecimal(2)
        // datasets['spends'][0..1] = [] // [Decimal.2], straight. sum(costMicros)/1000000
      }
    } catch (error) {
      console.log(error);
      if (redirableErrors.indexOf(error.constructor) >= 0) {
        if (
          await uxQueue.confirm(
            'redir',
            APIMessage.notloggedin({ confirm: true }),
          )
        ) {
          // console.trace('Redirecting', confirmResult)
          window.location.href =
            config.serverurl + 'users/redirect_auth0_login_page/';
        }
        return;
      } else if (retriableErrors.indexOf(error.constructor) >= 0) {
        let confirmMessage = '';
        if (error instanceof ServerDeclineError) {
          confirmMessage = await APIMessage.declined({
            response: error.response,
            confirm: true,
          });
        } else if (error instanceof InternalServerError) {
          confirmMessage = APIMessage.servererrror({ confirm: true });
        } else if (error instanceof CommunicationError) {
          confirmMessage = APIMessage.failed({ confirm: true });
        } else {
          confirmMessage =
            'Fetching ads stats data failed. Will you try again?';
        }
        if (await uxQueue.confirm('retry', confirmMessage)) {
          await delay(apiAgent.options.retryDelay);
          return handleSelectedPeriods({ from, to, fromRight, toRight });
        }
      } else {
        if (!(error instanceof APIAgentError)) {
          console.log(
            '@handleSelectedPeriods unexpected error',
            error,
            error.stack,
          );
        }
        let confirmMessage =
          'Fetching ads stats data failed. Will you try again?';
        if (await uxQueue.confirm('retry', confirmMessage)) {
          await delay(apiAgent.options.retryDelay);
          return handleSelectedPeriods({ from, to, fromRight, toRight });
        }
      }
    }
  };

  return (
    <>
      {projectLoaded && spendsLoaded ? (
        <>
          <Link
            to="#"
            onClick={() => {
              window.history.back();
            }}
            className="bg-white px-5 xs:px-10 py-5 font-semibold text-base leading-[17.6px] border-b border-[#E9ECF1] flex items-center"
          >
            <div className="w-[6px] h-[10px]">{svgIcons.left_arrow}</div>
            <div className="indent-3">Back</div>
          </Link>
          <div className="px-5 xs:px-10 py-4 xs:py-8 bg-white flex flex-wrap gap-8 justify-between">
            <div className="grid gap-2 items-center md:flex md:gap-8 relative w-full md:w-auto md:min-w-[600px] md:max-w-[1000px]">
              <div className="w-inherit aspect-[5/2] md:aspect-square md:h-full bg-[#E6EFFB] rounded relative">
                <img
                  src={project.property.highlight_photo || ''}
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/assets/images/empty.png';
                  }}
                  className="absolute top-0 left-0 w-full h-full object-cover rounded"
                />
                <div className="w-full h-full flex justify-center items-center">
                  <div className="w-5 text-[#8C94A3]">{svgIcons.noimg}</div>
                </div>
              </div>

              <div className="flex gap-4">
                <div className="grid gap-3">
                  <LinesEllipsis
                    text={project.property.name}
                    component="h5"
                    maxLine="2"
                    ellipsis="…"
                    trimRight
                    // basedOn="letters"
                  />
                  <div className="font-medium text-base leading-[20.8px]">
                    <span className="text-[#515868]">Google ID:</span>
                    <span className="ml-14">
                      {styleGoogleCustomerId(project.gad_customer_id) || ''}
                    </span>
                  </div>
                  <div className="font-medium text-base leading-[20.8px]">
                    <span className="text-[#515868]">Monthly Budget:</span>
                    <span className="ml-3">
                      {convertNumberType(monthlyBudget(project.budget_plan)) ||
                        ''}
                    </span>
                    <Link
                      to="#"
                      className="underline ml-2 font-semibold leading-[17.6px]"
                      onClick={() => setModal('EnterBudget')}
                    >
                      Adjust budget&nbsp;
                    </Link>
                  </div>
                </div>
                <div
                  className="absolute md:static top-0 left-2 flex justify-center items-center px-6 h-9 rounded-[34px] mt-1"
                  style={{ backgroundColor: projectStatusColor(project) }}
                >
                  <img
                    src={`/assets/images/${projectStatusName(
                      project,
                    ).toLowerCase()}-tile.svg`}
                    alt=""
                    width={16.67}
                    height={16.67}
                  />
                  <div className="indent-1 text-[14px] leading-4 font-semibold">
                    {projectStatusName(project)}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row-reverse mr-0 ml-auto">
              {projectStatusName(project) === 'Live' ? (
                <button
                  className="flair-btn flair-btn-md flair-btn-secondary w-[160px]"
                  onClick={(e) => setModal('ConfirmPause')}
                >
                  Pause campaign
                </button>
              ) : (
                projectStatusName(project) !== 'Pending' && (
                  <button
                    className="flair-btn flair-btn-md flair-btn-primary w-[160px]"
                    onClick={(e) => setModal('ConfirmActivate')}
                  >
                    Set Ads Live
                  </button>
                )
              )}
            </div>
          </div>
          <div className="px-5 xs:px-10 pt-10 pb-12 grid gap-10">
            {/* ============= BUDGET SECTION ============== */}
            <div className="grid gap-6">
              <div className="font-bold text-[28px] leading-[36.4px]">
                Budget
              </div>
              <div className="grid gap-4 md:flex">
                <div className="overflow-hidden md:overflow-visible flex gap-3 md:grid">
                  <ProjectTile
                    svg="wallet"
                    title="Budget"
                    value={monthlyBudget(project.budget_plan) || ''}
                  />
                  <ProjectTile
                    svg="spent"
                    title="Spent"
                    value={spendsSum !== null ? spendsSum : ''}
                  />
                  <ProjectTile
                    svg="balance"
                    title="Balance"
                    value={
                      spendsSum !== null
                        ? monthlyBudget(project.budget_plan) - spendsSum
                        : ''
                    }
                  />
                </div>
                <div className="bg-white py-[26px] px-6 md:px-[33px] w-full">
                  <div className="flex">
                    <div className="text-[#B0C5DE] w-5">{svgIcons.finance}</div>
                    <div className="font-normal text-[14px] leading-[18.2px] indent-2">
                      Money Spent
                    </div>
                  </div>
                  <div className="h-[236px] w-full mt-2">
                    {spendsSum === null ? (
                      <div className="no-data-txt">{NODATA}</div>
                    ) : (
                      <BarChart
                        setlabels={spendsSetLabel ? [spendsSetLabel] : []}
                        labels={spendsSum !== null ? spendLabels : []}
                        datasets={spendsSum !== null ? [spends] : []}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* ============= METRICS SECTION ============= */}
            <div className="grid gap-4">
              <div className="flex justify-between">
                <div className="font-bold text-[28px] leading-[36.4px]">
                  Metrics
                </div>
                <div className="relative">
                  <Dropdown>
                    <Dropdown.Toggle>
                      {(props) => (
                        <Button
                          {...props}
                          className="flair-btn flair-btn-md flair-btn-quaternary w-auto pl-5 pr-4 font-normal text-base gap-2"
                          style={
                            props['aria-expanded']
                              ? {
                                  border: '1px solid #687BFE',
                                  color: '#687BFE',
                                }
                              : {}
                          }
                        >
                          <div
                            className="w-[15px] opacity-50"
                            style={
                              props['aria-expanded'] ? { color: '#687BFE' } : {}
                            }
                          >
                            {svgIcons.calendar_filled}
                          </div>
                          <div>{pickerTitle}</div>
                          <div className="w-2">
                            {props['aria-expanded']
                              ? svgIcons.dropup
                              : svgIcons.dropdown}
                          </div>
                        </Button>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {(menuProps, meta) => (
                        <ul
                          {...menuProps}
                          className="fixed w-full xs:w-auto bottom-0 xs:absolute xs:bottom-auto xs:top-10 right-0 z-10 rounded-lg bg-white border border-[#E9ECF1] shadow-[0_20.3px_37.71px_rgba(183, 188, 200, 0.11)] whitespace-nowrap flex"
                          style={{
                            boxShadow:
                              '0px 20.3px 37.71px rgba(183, 188, 200, 0.11)',
                            transition:
                              'visibility 500ms, opacity 500ms, transform 500ms',
                            visibility: meta.show ? 'visible' : 'hidden',
                            opacity: meta.show ? '1' : '0',
                            transform: meta.show
                              ? 'translateY(6px)'
                              : 'translateY(-4px)',
                          }}
                        >
                          <DayPickerBody
                            onClickApply={async ({
                              from,
                              to,
                              fromRight,
                              toRight,
                            }) => {
                              try {
                                meta.toggle();
                                await handleSelectedPeriods({
                                  from,
                                  to,
                                  fromRight,
                                  toRight,
                                });
                              } catch (error) {
                                await uxDialog.alert({ text: error });
                              }
                            }}
                            onClickCancel={() => meta.toggle()}
                          />
                        </ul>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="bg-white py-[21px] px-4 w-full">
                  <div className="flex justify-between mb-2">
                    <div className="flex gap-2 items-center">
                      <div className="text-[#B0C5DE] w-[22px]">
                        {svgIcons.pc_filled}
                      </div>
                      <div className="font-normal text-[14px] leading-[18.2px]">
                        Clicks
                      </div>
                      <div
                        className="text-[#B0C5DE] w-4"
                        data-for="chart-info"
                        data-tip="How many people clicked on your ads"
                      >
                        {svgIcons.exclamation}
                      </div>
                    </div>

                    <ChartDiff
                      valuesLength={get(statSums, 'clicks.length', 0)}
                      firstVal={get(statSums, 'clicks.0', '')}
                      secondVal={get(statSums, 'clicks.1', '')}
                      isPositive={true}
                    />
                  </div>
                  <div className="h-[200px]">
                    {get(statDataSets, 'clicks', []).length === 0 ? (
                      <div className="no-data-txt">{NODATA}</div>
                    ) : (
                      <LineChart
                        setlabels={statSetLabels}
                        labels={statLabels}
                        datasets={get(statDataSets, 'clicks', [])}
                      />
                    )}
                  </div>
                </div>
                <div className="bg-white py-[21px] px-4 w-full">
                  <div className="flex justify-between mb-2">
                    <div className="flex gap-2 items-center">
                      <div className="text-[#B0C5DE] w-[21.64px]">
                        {svgIcons.impressions}
                      </div>
                      <div className="font-normal text-[14px] leading-[18.2px]">
                        Impressions
                      </div>
                      <div
                        className="text-[#B0C5DE] w-4"
                        data-for="chart-info"
                        data-tip="How many people viewed on your ads"
                      >
                        {svgIcons.exclamation}
                      </div>
                    </div>
                    <ChartDiff
                      valuesLength={get(statSums, 'impressions.length', 0)}
                      firstVal={get(statSums, 'impressions.0', '')}
                      secondVal={get(statSums, 'impressions.1', '')}
                      isPositive={true}
                    />
                  </div>
                  <div className="h-[200px]">
                    {get(statDataSets, 'impressions', []).length === 0 ? (
                      <div className="no-data-txt">{NODATA}</div>
                    ) : (
                      <BarChart
                        setlabels={statSetLabels}
                        labels={statLabels}
                        datasets={get(statDataSets, 'impressions', [])}
                      />
                    )}
                  </div>
                </div>
                <div className="bg-white py-[21px] px-4 w-full">
                  <div className="flex justify-between mb-2">
                    <div className="flex gap-2 items-center">
                      <div className="text-[#B0C5DE] w-4">
                        {svgIcons.filter_filled}
                      </div>
                      <div className="font-normal text-[14px] leading-[18.2px]">
                        Conversion
                      </div>
                      <div
                        className="text-[#B0C5DE] w-4"
                        data-for="chart-info"
                        data-tip="How many people converted on your website from your campaigns"
                      >
                        {svgIcons.exclamation}
                      </div>
                    </div>
                    <ChartDiff
                      valuesLength={get(statSums, 'conversions.length', 0)}
                      firstVal={get(statSums, 'conversions.0', null)}
                      secondVal={get(statSums, 'conversions.1', null)}
                      isPositive={true}
                    />
                  </div>
                  <div className="h-[200px]">
                    {get(statDataSets, 'clicks', []).length === 0 ? (
                      <div className="no-data-txt">{NODATA}</div>
                    ) : (
                      <LineChart
                        setlabels={statSetLabels}
                        labels={statLabels}
                        datasets={get(statDataSets, 'conversions', [])}
                      />
                    )}
                  </div>
                </div>
                <div className="bg-white py-[21px] px-4 w-full">
                  <div className="flex justify-between mb-2">
                    <div className="flex gap-2 items-center">
                      <div className="text-[#B0C5DE] w-[18px]">
                        {svgIcons.percent_filled}
                      </div>
                      <div className="font-normal text-[14px] leading-[18.2px]">
                        Conver. Rate
                      </div>
                      <div
                        className="text-[#B0C5DE] w-4"
                        data-for="chart-info"
                        data-tip="The percentage of users from Google Ads who converted on your website"
                      >
                        {svgIcons.exclamation}
                      </div>
                    </div>
                    <ChartDiff
                      valuesLength={get(statSums, 'conversionRates.length', 0)}
                      firstVal={get(statSums, 'conversionRates.0', null)}
                      secondVal={get(statSums, 'conversionRates.1', null)}
                      isPositive={true}
                    />
                  </div>
                  <div className="h-[200px]">
                    {get(statDataSets, 'clicks', []).length === 0 ? (
                      <div className="no-data-txt">{NODATA}</div>
                    ) : (
                      <LineChart
                        setlabels={statSetLabels}
                        labels={statLabels}
                        datasets={get(statDataSets, 'conversionRates', [])}
                      />
                    )}
                  </div>
                </div>
                <div className="bg-white py-[21px] px-4 w-full">
                  <div className="flex justify-between mb-2">
                    <div className="flex gap-2 items-center">
                      <div className="text-[#B0C5DE] w-[12.22px]">
                        {svgIcons.click_filled}
                      </div>
                      <div className="font-normal text-[14px] leading-[18.2px]">
                        CPC
                      </div>
                      <div
                        className="text-[#B0C5DE] w-4"
                        data-for="chart-info"
                        data-tip="How much you paid per click"
                      >
                        {svgIcons.exclamation}
                      </div>
                    </div>
                    <ChartDiff
                      valuesLength={get(statSums, 'CPC.length', 0)}
                      firstVal={get(statSums, 'CPC.0', null)}
                      secondVal={get(statSums, 'CPC.1', null)}
                      isPositive={false}
                      prefix="$"
                    />
                  </div>
                  <div className="h-[200px]">
                    {get(statDataSets, 'clicks', []).length === 0 ? (
                      <div className="no-data-txt">{NODATA}</div>
                    ) : (
                      <LineChart
                        setlabels={statSetLabels}
                        labels={statLabels}
                        datasets={get(statDataSets, 'CPC', [])}
                      />
                    )}
                  </div>
                </div>
                <div className="bg-white py-[21px] px-4 w-full">
                  <div className="flex justify-between mb-2">
                    <div className="flex gap-2 items-center">
                      <div className="text-[#B0C5DE] w-5">
                        {svgIcons.money_filled}
                      </div>
                      <div className="font-normal text-[14px] leading-[18.2px]">
                        Ad spend
                      </div>
                      <div
                        className="text-[#B0C5DE] w-4"
                        data-for="chart-info"
                        data-tip="This is how much you’ve spent on Google Ads"
                      >
                        {svgIcons.exclamation}
                      </div>
                    </div>
                    <ChartDiff
                      valuesLength={get(statSums, 'spends.length', 0)}
                      firstVal={get(statSums, 'spends.0', null)}
                      secondVal={get(statSums, 'spends.1', null)}
                      isPositive={true}
                      prefix="$"
                    />
                  </div>
                  <div className="h-[200px]">
                    {get(statDataSets, 'clicks', []).length === 0 ? (
                      <div className="no-data-txt">{NODATA}</div>
                    ) : (
                      <BarChart
                        setlabels={statSetLabels}
                        labels={statLabels}
                        datasets={get(statDataSets, 'spends', [])}
                      />
                    )}
                  </div>
                </div>
                <ReactTooltip
                  id="chart-info"
                  place="right"
                  type="light"
                  effect="solid"
                  multiline={true}
                  // className="__tooltip"
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <ProjectSkeleton />
      )}

      <InputModal
        show={modal === 'EnterBudget'}
        // showInput = {true}
        onHide={() => setModal(null)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.adjust,
          parentClass: 'text-[#687BFE] bg-[#687BFE]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Adjust Monthly Budget"
        txt="Your monthly budget can be adjusted once a month. Your campaigns will not spend more than this in one month."
        // btn1='Confirm'
        // btn2='Cancel'
        inputPlaceholder="Enter Your New Monthly Budget"
        inputBackImg="/assets/images/modal-dollar.svg"
        handleConfirm={handleConfirmBudget}
        // handleCancel={() => setModal(null)}
      />

      <SuccessModal
        show={modal === 'UpdatedBudget'}
        onHide={() => setModal(null)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.success,
          parentClass: 'text-[#38D086] bg-[#38D086]/10',
          childClass: 'w-[30px] h-[17px]',
        }}
        title="Success"
        txt="The budget was successfully updated."
        modalConfirmBtn="Back to Campaign"
        handleSuccess={() => setModal(null)}
      />

      <ConfirmModal
        show={modal === 'ConfirmDisable'}
        onHide={() => setModal(null)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.warning,
          parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Disable campaign"
        txt="If you turn off campaign you stop your campaign. Are you sure you want to do it?"
        // btn1='Confirm'
        // btn2='Cancel'
        handleConfirm={handleConfirmDisable}
        // handleCancel={() => setModal(null)}
      />

      <SuccessModal
        show={modal === 'Pending'}
        onHide={() => setModal(null)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.success,
          parentClass: 'text-[#38D086] bg-[#38D086]/10',
          childClass: 'w-[30px] h-[17px]',
        }}
        title="Success"
        txt="The campaign was successfully disabled."
        modalConfirmBtn="Back to Main"
        handleSuccess={() => setModal(null)}
      />

      <ConfirmModal
        show={modal === 'ConfirmPause'}
        onHide={() => setModal(null)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.warning,
          parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Pause campaign"
        txt="If you pause your campaigns, your ads will stop running and you won't get any more leads. Are you sure you want to pause?"
        // btn1='Confirm'
        // btn2='Cancel'
        handleConfirm={handleConfirmPause}
        // handleCancel={() => setModal(null)}
      />

      <SuccessModal
        show={modal === 'Paused'}
        onHide={() => setModal(false)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.success,
          parentClass: 'text-[#38D086] bg-[#38D086]/10',
          childClass: 'w-[30px] h-[17px]',
        }}
        title="Success"
        txt="The campaign was successfully paused."
        modalConfirmBtn="Back to Main"
        handleSuccess={() => setModal(null)}
      />

      <SuccessModal
        show={modal === 'ServerAcceptedRequest'}
        onHide={() => setModal(false)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.success,
          parentClass: 'text-[#38D086] bg-[#38D086]/10',
          childClass: 'w-[30px] h-[17px]',
        }}
        title="Success"
        txt="Your request was accepted. It may take some time to process your request."
        modalConfirmBtn="Back to Main"
        handleSuccess={() => setModal(null)}
      />

      <ConfirmModal
        show={modal === 'ConfirmActivate'}
        onHide={() => setModal(null)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.warning,
          parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Set Ads Live"
        txt="If you proceed, your ads will go live and start spending your budget. Are you sure you'd like to proceed?"
        // btn1='Confirm'
        // btn2='Cancel'
        handleConfirm={handleConfirmActivate}
        // handleCancel={() => setModal(null)}
      />

      <SuccessModal
        show={modal === 'Activated'}
        onHide={() => setModal(false)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.success,
          parentClass: 'text-[#38D086] bg-[#38D086]/10',
          childClass: 'w-[30px] h-[17px]',
        }}
        title="Success"
        txt="The campaign was successfully activated."
        modalConfirmBtn="Back to Main"
        handleSuccess={() => setModal(null)}
      />
    </>
  );
}
