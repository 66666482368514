import React, { useState, useEffect, memo } from 'react';
import { useOutletContext } from 'react-router-dom';

import { Line, Doughnut } from 'react-chartjs-2';
import ReactTooltip from 'react-tooltip';
import Skeleton from 'react-loading-skeleton';

import { apiAgent } from '../../utils/apicall';
import OffsetPaginator from '../../components/paginate/OffsetPaginator';
import { svgIcons } from '../../components/svgIcons/svgIcons';
import { getDateArr } from '../../utils/publicFunctions';

const BudgetOverview = () => {
  const [project, period] = useOutletContext();

  const [averageSpendingByHour, setAverageSpendingHour] = useState([]);
  const [campaignCost, setCampaignCost] = useState([]);
  const [adNetworkCosts, setAdNetworkCosts] = useState([]);

  // pagination
  const [showCampaignCost, setShowCampaignCost] = useState([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [size, setSize] = useState(10);
  const [infoLoaded, setInfoLoaded] = useState(false);
  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };
  const [sortingData, setSortingData] = useState([]);
  const { items, requestSort, sortConfig } = useSortableData(campaignCost);
  useEffect(() => {
    setSortingData(items);
  }, [items]);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? 'w-2' : undefined;
  };
  const getArrow = (name) => {
    if (!sortConfig) {
      return;
    } else if (sortConfig.direction == 'ascending') {
      return svgIcons.dropdown;
    } else if (sortConfig.direction == 'descending') {
      return svgIcons.dropup;
    }
  };
  const handleSelectPage = ({ offset: _offset, size: _size }) => {
    setOffset(_offset);
    setSize(_size);
  };
  useEffect(() => {
    setTotal(sortingData.length);
    const arr = [];
    let last = 0;
    if (offset + size > sortingData.length) {
      last = sortingData.length;
    } else {
      last = offset + size;
    }
    for (let i = offset; i < last; i++) {
      arr.push(sortingData[i]);
    }
    setShowCampaignCost(arr);
  }, [sortingData, offset, size]);
  // end pagination
  const fetchOverview = async () => {
    try {
      setInfoLoaded(false);
      var path = '/projects/';
      if (
        window.location.href.search('/s/') >= 0 ||
        window.location.href.search('/share/') >= 0
      )
        path = '/reports/';
      const response = await apiAgent.get({
        path: path + `${project}/budget_overview/`,
        params: {
          period,
        },
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const data = await response.json();

      // average daily spending by hour
      setAverageSpendingHour(() =>
        data.average_spending_hour.reduce((temp, item) => {
          temp[item.segments.hour] = item.metrics.costMicros / 1000000 || 0;
          return temp;
        }, Array(24).fill(0)),
      );

      // spending by campaign
      setCampaignCost(() => {
        const costSum = data.campaign_spending.reduce(
          (sum, item) => (sum += item.metrics.costMicros / 1000000 || 0),
          0,
        );
        return data.campaign_spending.map((item) => {
          return {
            name: item.campaign.name,
            status: item.campaign.status,
            cost: item.metrics.costMicros / 1000000 || 0,
            percent: item.metrics.costMicros / 10000 / costSum || 0,
          };
        });
      });

      // cost by ad network
      setAdNetworkCosts(() =>
        data.ad_network_spending.map((item) => {
          return {
            name: item.segments.adNetworkType,
            cost: item.metrics.costMicros / 1000000 || 0,
          };
        }),
      );
      setInfoLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!period.length || !project) {
      return;
    }
    fetchOverview();
  }, [project, period]);

  return project && infoLoaded ? (
    <div className="py-6" id="budget-overview">
      <div className="bg-white px-8 py-7 border border-[#E9ECF1] rounded">
        <div className="mb-6 flex justify-between items-start flex-wrap gap-3">
          <div className="font-semibold text-[18px] capitalize flex items-center gap-2">
            Average Daily Spending by hour
            <div
              className="w-4 text-[#B7BCC8]"
              data-for="chart-info"
              data-tip="The average amount of money spent each day by hour"
            >
              {svgIcons.exclamation}
            </div>
          </div>
          <div className="ml-auto">
            <p className="text-[18px] font-semibold text-right">
              {averageSpendingByHour.length > 0 &&
                '$' +
                  parseFloat(
                    averageSpendingByHour
                      .reduce((sum, item) => (sum += item || 0), 0)
                      .toFixed(2),
                  ).toLocaleString()}
            </p>
            <p className="font-medium text-[12px] leading-[15.6px] capitalize text-right">
              ad spend
            </p>
          </div>
        </div>
        <div className="h-52">
          <div className="flex ml-3">
            <div className="text-[#EA7E32] w-3">{svgIcons.dollar}</div>
          </div>
          <Line
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  borderColor: '#E9EBF1',
                  borderWidth: 1,
                  borderRadius: 4,
                  displayColors: true,
                  bodySpacing: 10,
                  backgroundColor: 'white',
                  bodyColor: '#515868',
                  titleColor: '#161D37',
                  usePointStyle: true,
                  callbacks: {
                    labelPointStyle: function (context) {
                      return {
                        pointStyle: 'circle',
                      };
                    },
                  },
                },
              },
              interaction: {
                intersect: false,
                mode: 'index',
              },
              elements: {
                point: {
                  radius: 0,
                  hoverRadius: 8,
                },
              },
              tension: 0.4,
            }}
            data={{
              labels: Array.from(Array(24).keys()).map((item) => `${item}:00`),
              datasets: [
                {
                  label: 'Cost',
                  backgroundColor: '#EA7E32',
                  borderColor: '#EA7E32',
                  borderWidth: 3,
                  fill: false,
                  data: averageSpendingByHour.map(
                    (item) => item / getDateArr(period).length || 0,
                  ),
                  hoverBackgroundColor: '#ffffff',
                },
              ],
            }}
          />
        </div>
      </div>
      <div className="flex gap-4 flex-wrap-reverse mt-6">
        <div className="bg-white py-4 overflow-auto grow-[2] border border-[#E9ECF1] rounded">
          <div className="text-[18px] leading-[23.4px] font-semibold px-4">
            Spending by Ad Campaign
          </div>
          <table className="w-full mt-6">
            <thead>
              <tr className="text-[#707787] bg-[#F2F5F966] border-b-[#E9ECF1] border-b">
                <th>
                  <button
                    onClick={() => requestSort('name')}
                    className="text-xs font-medium text-left uppercase px-2 py-6 flex gap-2 justify-between items-center"
                  >
                    <div>campaign</div>
                    <div className={getClassNamesFor('name')}>
                      {getArrow('name')}
                    </div>
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => requestSort('status')}
                    className="text-xs font-medium text-left uppercase px-2 py-6 flex gap-2 justify-between items-center"
                  >
                    <div>campaign status</div>
                    <div className={getClassNamesFor('status')}>
                      {getArrow('status')}
                    </div>
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => requestSort('cost')}
                    className="text-xs font-medium text-left uppercase px-2 py-6 flex gap-2 justify-between items-center"
                  >
                    <div>cost</div>
                    <div className={getClassNamesFor('cost')}>
                      {getArrow('cost')}
                    </div>
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => requestSort('percent')}
                    className="text-xs font-medium text-left uppercase px-2 py-6 flex gap-2 justify-between items-center"
                  >
                    <div>%</div>
                    <div className={getClassNamesFor('percent')}>
                      {getArrow('percent')}
                    </div>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {showCampaignCost.map((item, index) => {
                return (
                  <tr key={index} className="border-b-[#E9ECF1] border-b">
                    <td className="text-[14px] font-medium px-2 py-4">
                      {item.name}
                    </td>
                    <td className="text-[14px] font-medium px-2 py-4">
                      {item.status}
                    </td>
                    <td className="text-[14px] font-medium px-2 py-4">
                      ${parseFloat(item.cost.toFixed(2)).toLocaleString() || 0}
                    </td>
                    <td className="text-[14px] font-medium px-2 py-4">
                      {item.percent.toFixed(2) || 0}%
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="px-6" id="budget-overview-pag">
            <OffsetPaginator onSelectPage={handleSelectPage} total={total} />
          </div>
        </div>
        <div className="bg-white p-4 grow border border-[#E9ECF1] rounded">
          <div className="text-[18px] font-semibold flex items-center gap-2">
            Spending Ad Network Type
            <div
              className="w-4 text-[#B7BCC8]"
              data-for="chart-info"
              data-tip="How much was spent on advertising by network type"
            >
              {svgIcons.exclamation}
            </div>
          </div>
          <div className="w-80 mt-6 mx-auto">
            <Doughnut
              options={{
                plugins: {
                  legend: {
                    title: {
                      padding: 100,
                    },
                    position: 'bottom',
                    align: 'start',
                    labels: {
                      usePointStyle: true,
                      boxWidth: 12,
                      padding: 10,
                    },
                  },
                  tooltip: {
                    borderColor: '#E9EBF1',
                    borderWidth: 1,
                    borderRadius: 4,
                    displayColors: true,
                    bodySpacing: 10,
                    backgroundColor: 'white',
                    bodyColor: '#515868',
                    titleColor: '#161D37',
                    usePointStyle: true,
                    callbacks: {
                      labelPointStyle: function (context) {
                        return {
                          pointStyle: 'circle',
                        };
                      },
                    },
                  },
                },
              }}
              data={{
                labels: adNetworkCosts.map((item) => item.name),
                datasets: [
                  {
                    label: 'Ad Network Cost',
                    data: adNetworkCosts.map((item) => item.cost.toFixed(2)),
                    backgroundColor: [
                      '#A8B1EF',
                      '#FF7F5F',
                      '#38D086',
                      '#F6D214',
                      '#DB7DF2',
                      '#59D3EE',
                    ],
                    hoverOffset: 4,
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
      <ReactTooltip
        id="chart-info"
        place="right"
        type="light"
        effect="solid"
        multiline={true}
        className="w-40"
      />
    </div>
  ) : (
    <div className="py-6">
      <div className="skeleton-background px-8 py-7">
        <div className="mb-8 flex justify-between items-start gap-3 flex-wrap">
          <p className="text-[18px] capitalize">
            <Skeleton
              width={270}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </p>
          <div className="ml-auto">
            <p className="text-[18px] flex justify-end">
              <Skeleton
                width={100}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </p>
            <p className="text-sm flex justify-end">
              <Skeleton
                width={50}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </p>
          </div>
        </div>
        <Skeleton
          height={200}
          baseColor="rgba(202, 220, 237, 0.7)"
          highlightColor="rgba(219, 230, 242, 1)"
        />
      </div>
      <div className="flex flex-wrap-reverse gap-6 mt-6">
        <div className="skeleton-background py-4 grow-[2] overflow-hidden grid grid-cols-1">
          <div className="text-[18px] px-4">
            <Skeleton
              width={230}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </div>
          <table className="w-full mt-6">
            <thead>
              <tr className="border-b-[#E9ECF1] border-b">
                {[...Array(4).keys()].map((row, index) => (
                  <th key={index} className="text-xs px-2 py-6">
                    <Skeleton
                      width={50 + Math.random() * 100}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {[...Array(4).keys()].map((row, index) => (
                <tr key={index} className="border-b-[#E9ECF1] border-b">
                  {[...Array(4).keys()].map((row, index) => (
                    <th key={index} className="text-xs px-2 py-4">
                      <Skeleton
                        width={50 + Math.random() * 100}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </th>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="skeleton-background p-4 grow">
          <div className="text-[18px]">
            <Skeleton
              width={150}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </div>
          <div className="mt-6 w-48 mx-auto">
            <Skeleton
              height={192}
              borderRadius={192}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </div>
          <div className="mt-6 grid grid-cols-2 gap-3 w-52 mx-auto">
            {[...Array(4).keys()].map((row, index) => (
              <div key={index} className="">
                <Skeleton
                  width={90}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BudgetOverview);
