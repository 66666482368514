import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash-es';

import { apiAgent } from '../utils/apicall';
import InputModal from '../components/modalbootstrap/InputModal';
import {
  fetchPromptRequest,
  fetchShow,
} from '../features/uxdialog/uxpromptSlice';
import { setUserInfo } from '../features/userInfo/userInfoSlice';
import config from '../utils/config';
import ProSidebar from '../components/prosidebar/ProSidebar';
import { Navbar } from '../components/navbar/Navbar';
import { svgIcons } from '../components/svgIcons/svgIcons';

export default function Home() {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const dispatch = useDispatch();
  const promptShowFlag = useSelector(fetchShow);
  const promptRequest = useSelector(fetchPromptRequest);

  const fetchAccountInfo = async () => {
    try {
      const response = await apiAgent.get({
        path: '/users/me',
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      return await response.json();
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  const getAccountInfo = async () => {
    let sendURL = window.location.pathname + window.location.search;
    // set user info
    const Obj = await fetchAccountInfo();
    if (!Obj) {
      // await uxDialog.alert({
      //   text: `We're redirecting you to the login page.`,
      // });
      window.location.href = `${config.serverurl}users/redirect_auth0_login_page/?target_page=app.demo.getflair.io`;
      return;
    }
    dispatch(setUserInfo(Obj));
  };
  // const getProperties = async () => {
  //   try {
  //     const response = await apiAgent.get({
  //       path: '/properties/',
  //       options: apiAgent.popularOptions.onConfirmExceptDeclined,
  //     });
  //     const props = await response.json();
  //     dispatch(setProperties(props));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  useEffect(() => {
    getAccountInfo();
    // getProperties();
    const intervalID = setInterval(fetchAccountInfo, 30000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  return (
    userInfo && (
      <>
        <Navbar toggle={setSidebarToggle} />
        <div className="flex">
          <ProSidebar toggle={sidebarToggle} />
          <div className="w-full min-h-screen xs:grow text-[#161D37] bg-[#F5FAFF]">
            <Outlet />
          </div>
        </div>
        <InputModal
          show={promptShowFlag}
          mark={{
            icon: svgIcons.adjust,
            parentClass: 'text-[#687BFE] bg-[#687BFE]/10',
            childClass: 'w-7 h-7',
          }}
          // headerimage={get(promptRequest, 'headerImagePath', null)}
          title={get(promptRequest, 'title', null)}
          txt={get(promptRequest, 'message', null)}
          inputPlaceholder="Enter Your New Monthly Budget"
          inputType="number"
          inputBackImg="/assets/images/modal-dollar.svg"
          // primaryButtonLabel={get(promptRequest, 'primaryButtonLabel', 'Confirm')}
          // secondaryButtonLabel={get(promptRequest, 'secondaryButtonLabel', 'Cancel')}
          handleConfirm={(value) => {
            get(promptRequest, 'handlePrimary', () => {})(value);
          }}
          // handleSecondary={(value) => {get(promptRequest, 'handleSecondary', () => {})(value)}}
          onClose={(value) => {
            get(promptRequest, 'handleClose', () => {})(value);
          }}
          // onHide={(value) => {get(promptRequest, 'handleClose', () => {})(value)}}
        />
      </>
    )
  );
}
