import React, { useState, useEffect, memo } from 'react';
import ReactTooltip from 'react-tooltip';
import { apiAgent } from '../../utils/apicall';
import { DateTime } from 'luxon';
import { useOutletContext } from 'react-router-dom';
import { getDateArr } from '../../utils/publicFunctions';
import OffsetPaginator from '../../components/paginate/OffsetPaginator';
import Skeleton from 'react-loading-skeleton';
import { svgIcons } from '../../components/svgIcons/svgIcons';
import { ContainerWithBar } from '../../components/Container/ContainerWithChart';

const AccountOverview = () => {
  const [project, period] = useOutletContext();

  const [labelsBySegment, setLabelsBySegment] = useState([]); // chart label

  const [dataPerSegment, setDataPerSegment] = useState({
    conversions: [],
    cost: [],
    ctr: [],
    clicks: [],
    impressions: [],
    costPerConversion: [],
  });
  const [dataPerCampaign, setDataPerCampaign] = useState([]);

  const [showDataPerCampaign, setShowDataPerCampaign] = useState([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [size, setSize] = useState(10);

  const [infoLoaded, setInfoLoaded] = useState(false);
  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };
  const [sortingData, setSortingData] = useState([]);
  const { items, requestSort, sortConfig } = useSortableData(dataPerCampaign);
  useEffect(() => {
    setSortingData(items);
  }, [items]);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? 'w-2' : '';
  };
  const getArrow = (name) => {
    if (!sortConfig) {
      return;
    } else if (sortConfig.direction === 'ascending') {
      return svgIcons.dropdown;
    } else if (sortConfig.direction === 'descending') {
      return svgIcons.dropup;
    }
  };
  const handleSelectPage = ({ offset: _offset, size: _size }) => {
    setOffset(_offset);
    setSize(_size);
  };

  useEffect(() => {
    setTotal(sortingData.length);
    const arr = [];
    let last = 0;
    if (offset + size > sortingData.length) {
      last = sortingData.length;
    } else {
      last = offset + size;
    }
    for (let i = offset; i < last; i++) {
      arr.push(sortingData[i]);
    }
    setShowDataPerCampaign(arr);
  }, [sortingData, offset, size]);

  useEffect(() => {
    if (!period.length || !project) {
      return;
    }
    fetchOverview();
  }, [project, period]);

  const fetchOverview = async () => {
    try {
      setInfoLoaded(false);
      var path = '/projects/';
      if (
        window.location.href.search('/s/') >= 0 ||
        window.location.href.search('/share/') >= 0
      )
        path = '/reports/';
      const response = await apiAgent.get({
        path: path + `${project}/account_overview/`,
        params: {
          period,
        },
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const data = await response.json();

      // set data
      const dateArr = getDateArr(period);
      const labels =
        dateArr.length > 1
          ? dateArr.map((item) => DateTime.fromSQL(item).toFormat('LLL d'))
          : Array.from(Array(24).keys()).map((item) => `${item}:00`);
      setLabelsBySegment(labels);

      setDataPerSegment(() =>
        data.per_segment.reduce(
          (obj, item) => {
            let index = -1;
            if (item.segments.date) {
              index = dateArr.indexOf(item.segments.date);
            } else {
              index = item.segments.hour;
            }
            obj.clicks[index] = item.metrics.clicks / 1 || 0;
            obj.conversions[index] = item.metrics.conversions || 0;
            obj.cost[index] = item.metrics.costMicros / 1000000 || 0;
            obj.impressions[index] = item.metrics.impressions / 1 || 0;
            obj.ctr[index] = item.metrics.ctr * 100 || 0;
            obj.costPerConversion[index] =
              item.metrics.costPerConversion / 1000000 || 0;
            obj.allConversions[index] =
              item.metrics.allConversions / 1000000 || 0;
            return obj;
          },
          {
            clicks: Array(labels.length).fill(0),
            conversions: Array(labels.length).fill(0),
            cost: Array(labels.length).fill(0),
            impressions: Array(labels.length).fill(0),
            ctr: Array(labels.length).fill(0),
            costPerConversion: Array(labels.length).fill(0),
            allConversions: Array(labels.length).fill(0),
          },
        ),
      );
      setDataPerCampaign(() =>
        data.per_campaign.map((item) => ({
          campaignName: item.campaign.name,
          campaignStatus: item.campaign.status,
          impressions: item.metrics.impressions / 1 || 0,
          clicks: item.metrics.clicks / 1 || 0,
          ctr: parseFloat((100 * item.metrics.ctr).toFixed(2)) || 0,
          cost: parseFloat((item.metrics.costMicros / 1000000).toFixed(2)) || 0,
          conversions: parseFloat(item.metrics.conversions.toFixed(2)) || 0,
          costPerConversion:
            parseFloat((item.metrics.costPerConversion / 1000000).toFixed(2)) ||
            0,
          allConversions:
            parseFloat(item.metrics.allConversions.toFixed(2)) || 0,
        })),
      );
      setInfoLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  return infoLoaded ? (
    <div className="py-6" id="account-overview">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <ContainerWithBar
          title="total conversions"
          sub-title="conversions"
          statistics={dataPerSegment.conversions
            .reduce((sum, item) => (sum += item || 0), 0)
            .toFixed(2)}
          data-tip="The number of people that complete a desired goal (a conversion)"
          color="#F6D214"
          chart-label="Conversions"
          chart-data-labels={labelsBySegment}
          chart-data={dataPerSegment.conversions}
        />
        <ContainerWithBar
          title="total cost"
          sub-title="ad spend"
          statistics={dataPerSegment.cost
            .reduce((sum, item) => (sum += item || 0), 0)
            .toFixed(2)}
          data-tip="How much was spent on advertising"
          color="#A8B1EF"
          prefix="$ "
          chart-label="Cost"
          chart-data-labels={labelsBySegment}
          chart-data={dataPerSegment.cost}
        />
        <ContainerWithBar
          title="Average CTR"
          sub-title="CTR"
          statistics={(
            dataPerSegment.ctr.reduce((sum, item) => (sum += item || 0), 0) /
            labelsBySegment.length
          ).toFixed(2)}
          data-tip="How often users clicked on your ads"
          color="#B0C5DE"
          suffix="%"
          chart-label="CTR"
          chart-data-labels={labelsBySegment}
          chart-data={dataPerSegment.ctr}
        />
        <ContainerWithBar
          title="total clicks"
          sub-title="clicks"
          statistics={dataPerSegment.clicks.reduce(
            (sum, item) => (sum += item || 0),
            0,
          )}
          data-tip="How many clicks users made on your ads"
          color="#38D086"
          chart-label="Clicks"
          chart-data-labels={labelsBySegment}
          chart-data={dataPerSegment.clicks}
        />
        <ContainerWithBar
          title="total impressions"
          sub-title="impressions"
          statistics={dataPerSegment.impressions.reduce(
            (sum, item) => (sum += item || 0),
            0,
          )}
          data-tip="The number of times your ad or content has been displayed on a screen"
          color="#FF7F5F"
          chart-label="Impressions"
          chart-data-labels={labelsBySegment}
          chart-data={dataPerSegment.impressions}
        />
        <ContainerWithBar
          title="AV. Conversion Cost"
          sub-title="Conversion Cost"
          statistics={(
            dataPerSegment.cost.reduce((sum, item) => (sum += item || 0), 0) /
            dataPerSegment.conversions.reduce(
              (sum, item) => (sum += item || 0),
              0,
            )
          ).toFixed(2)}
          data-tip="The average cost per conversion is the total cost of conversions divided by the total number of conversions"
          color="#687BFE"
          prefix="$ "
          chart-label="Conversion Cost"
          chart-data-labels={labelsBySegment}
          chart-data={dataPerSegment.costPerConversion}
        />
      </div>
      <div className="bg-white border border-[#E9ECF1] rounded mt-6 xs:mt-10 grid grid-cols-1">
        <div className="w-full overflow-auto">
          <table className="w-full">
            <thead>
              <tr className="text-[#707787] bg-[#F2F5F966] border-b-[#E9ECF1] border-b">
                <th>
                  <button
                    type="button"
                    onClick={() => requestSort('campaignName')}
                    className="text-xs font-medium text-left uppercase px-2 py-6 flex gap-2 justify-between items-center"
                  >
                    campaign
                    <div className={getClassNamesFor('campaignName')}>
                      {getArrow('campaignName')}
                    </div>
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => requestSort('campaignStatus')}
                    className="text-xs font-medium text-left uppercase px-2 py-6 flex gap-2 justify-between items-center"
                  >
                    campaign status
                    <div className={getClassNamesFor('campaignStatus')}>
                      {getArrow('campaignStatus')}
                    </div>
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => requestSort('impressions')}
                    className="text-xs font-medium text-left uppercase px-2 py-6 flex gap-2 justify-between items-center"
                  >
                    impressions
                    <div className={getClassNamesFor('impressions')}>
                      {getArrow('impressions')}
                    </div>
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => requestSort('clicks')}
                    className="text-xs font-medium text-left uppercase px-2 py-6 flex gap-2 justify-between items-center"
                  >
                    clicks
                    <div className={getClassNamesFor('clicks')}>
                      {getArrow('clicks')}
                    </div>
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => requestSort('ctr')}
                    className="text-xs font-medium text-left uppercase px-2 py-6 flex gap-2 justify-between items-center"
                  >
                    ctr
                    <div className={getClassNamesFor('ctr')}>
                      {getArrow('ctr')}
                    </div>
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => requestSort('cost')}
                    className="text-xs font-medium text-left uppercase px-2 py-6 flex gap-2 justify-between items-center"
                  >
                    cost
                    <div className={getClassNamesFor('cost')}>
                      {getArrow('cost')}
                    </div>
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => requestSort('conversions')}
                    className="text-xs font-medium text-left uppercase px-2 py-6 flex gap-2 justify-between items-center"
                  >
                    conversions
                    <div className={getClassNamesFor('conversions')}>
                      {getArrow('conversions')}
                    </div>
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => requestSort('allConversions')}
                    className="text-xs font-medium text-left uppercase px-2 py-6 flex gap-2 justify-between items-center"
                  >
                    all conversions
                    <div className={getClassNamesFor('allConversions')}>
                      {getArrow('allConversions')}
                    </div>
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={() => requestSort('costPerConversion')}
                    className="text-xs font-medium text-left uppercase px-2 py-6 flex gap-2 justify-between items-center"
                  >
                    cost/conv.
                    <div className={getClassNamesFor('costPerConversion')}>
                      {getArrow('costPerConversion')}
                    </div>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {showDataPerCampaign.map((item, index) => {
                return (
                  <tr key={index} className="border-b-[#E9ECF1] border-b">
                    <td className="text-[14px] font-medium px-2 py-4">{`${
                      offset + index + 1
                    }. ${item.campaignName}`}</td>
                    <td className="text-[14px] font-medium px-2 py-4">
                      {item.campaignStatus}
                    </td>
                    <td className="text-[14px] font-medium px-2 py-4">
                      {item.impressions.toLocaleString() || 0}
                    </td>
                    <td className="text-[14px] font-medium px-2 py-4">
                      {item.clicks.toLocaleString() || 0}
                    </td>
                    <td className="text-[14px] font-medium px-2 py-4">
                      {item.ctr || 0}%
                    </td>
                    <td className="text-[14px] font-medium px-2 py-4">
                      ${item.cost.toLocaleString() || 0}
                    </td>
                    <td className="text-[14px] font-medium px-2 py-4">
                      {item.conversions.toLocaleString() || 0}
                    </td>
                    <td className="text-[14px] font-medium px-2 py-4">
                      {item.allConversions.toLocaleString() || 0}
                    </td>
                    <td className="text-[14px] font-medium px-2 py-4">
                      ${item.costPerConversion.toLocaleString() || 0}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="px-8" id="account-overview-pag">
          <OffsetPaginator onSelectPage={handleSelectPage} total={total} />
        </div>
      </div>
      <ReactTooltip
        id="chart-info"
        place="right"
        type="light"
        effect="solid"
        multiline={true}
        className="w-40"
      />
    </div>
  ) : (
    <div className="py-6">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {[...Array(6).keys()].map((row, index) => (
          <div key={index} className="skeleton-background px-8 py-7">
            <div className="mb-4 flex justify-between items-start">
              <p className="font-semibold text-[18px] leading-[23.4px] capitalize">
                <Skeleton
                  width={200}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </p>
              <div className="grid gap-1">
                <p className="text-[18px] font-semibold flex justify-end">
                  <Skeleton
                    width={100}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <p className="font-medium text-[12px] leading-[15.6px] capitalize flex justify-end">
                  <Skeleton
                    width={50}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
              </div>
            </div>
            <div className="h-52 xl:h-72">
              <Skeleton
                height={200}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="skeleton-background mt-6 xs:mt-10 grid grid-cols-1 overflow-hidden">
        <table>
          <thead>
            <tr className="border-b-[#E9ECF1] border-b">
              {[...Array(10).keys()].map((item, index) => (
                <th key={index} className="text-xs px-2 py-6">
                  <Skeleton
                    width={Math.random() * 100 + 50}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[...Array(5).keys()].map((row, i) => (
              <tr key={i} className="border-b-[#E9ECF1] border-b">
                {[...Array(10).keys()].map((col, j) => (
                  <td key={j} className="text-base px-2 py-4">
                    <Skeleton
                      width={Math.random() * 100 + 50}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(AccountOverview);
