import { useEffect, useState } from 'react';
import { Link, Outlet, useSearchParams, useLocation } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import moment from 'moment';
import { DateTime } from 'luxon';
import { svgIcons } from '../../components/svgIcons/svgIcons';
import { apiAgent } from '../../utils/apicall';
import { styleGoogleCustomerId } from '../../utils/publicFunctions';
import DatePickerDropdown from '../../components/dropdown/DatePickerDropdown';
import ProjectSelect from '../../components/select/ProjectSelect';
import { ProjectStatus } from '../../utils/constants';
import { REPORT_LIST } from '../../utils/constants';
import Skeleton from 'react-loading-skeleton';

export default function Campaign() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [project, setProject] = useState();
  const [period, setPeriod] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [projectId, setProjectId] = useState(null);
  const [selectedProject, setSelectedProject] = useState();
  const [loadingFlag, setLoadingFlag] = useState(false);

  useEffect(() => {
    document.title = 'Flair Property Report';
    (async () => {
      setLoadingFlag(true);
      const response = await apiAgent.get({
        path: 'projects/',
        params: {
          ordering: 'property__name',
          property__company__name: 'Flair Demo',
          ads_status__in: [ProjectStatus.ACTIVE].join(','),
        },
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      setLoadingFlag(false);
      setProjectList(await response.json());
    })();
    setPeriod(
      searchParams.get('period')
        ? searchParams.get('period').split('~')
        : [
            DateTime.fromJSDate(
              moment().subtract(1, 'month').startOf('month')._d,
            ).toFormat('yyyy-MM-dd'),
            DateTime.fromJSDate(
              moment().subtract(1, 'month').endOf('month')._d,
            ).toFormat('yyyy-MM-dd'),
          ],
    );
    setProjectId(searchParams.get('project'));
  }, []);

  const fetchProjectData = async () => {
    const projectId = searchParams.get('project');
    try {
      const response = await apiAgent.get({
        path: `/projects/${projectId}`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const projectBody = await response.json();
      setProject(projectBody);
      setSelectedProject(projectBody);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, [projectId]);

  useEffect(() => {
    if (selectedProject && period.length) {
      setSearchParams({
        project: selectedProject && selectedProject.id,
        period: period.join('~'),
      });
      setProjectId(selectedProject.id);
    }
  }, [selectedProject, period]);

  return (
    <>
      <div className="mx-5 xs:mx-10">
        <Link
          to="/properties"
          className="my-8 w-fit font-semibold text-sm leading-[15.4px] block"
        >
          <div className="inline-block w-1.5">{svgIcons.left_arrow}</div>
          <span className="ml-3">Back to Properties</span>
        </Link>
        <div className="flex items-center justify-between gap-6 flex-wrap pb-6 xs:border-b border-[#6F728B21]">
          <div>
            <div className="text-3xl leading-[41.6px] font-bold">
              Property Overview
            </div>
          </div>
          {loadingFlag ? (
            <Skeleton
              width={300}
              height={46}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
              borderRadius={4}
            />
          ) : (
            <ProjectSelect
              className="font-normal text-base leading-6 ml-auto w-full xs:w-auto"
              project={selectedProject}
              projectList={projectList}
              onChange={setSelectedProject}
              isLoading={!projectList.length}
            />
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 xs:mt-6 xs:px-10">
        {loadingFlag ? (
          <>
            <div className="skeleton-background py-6 px-5 xs:px-8 grid gap-8 lg:flex">
              <div className="w-full h-[129px] lg:w-[129px] object-cover rounded">
                <Skeleton
                  containerClassName="w-full"
                  height={129}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  borderRadius={4}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="bg-white py-6 px-5 xs:px-8 grid gap-8 lg:flex">
              <img
                className="w-full h-[129px] lg:w-[129px] object-cover rounded"
                src={
                  project &&
                  (project.property.highlight_photo ||
                    '/../assets/images/tile-noimg.svg')
                }
                alt=""
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/../assets/images/tile-noimg.svg';
                }}
              />
              <div className="w-full overflow-hidden">
                <LinesEllipsis
                  text={project && project.property.name}
                  className="leading-[36.4px] font-bold text-[28px]"
                  component="div"
                  maxLine="1"
                  ellipsis="…"
                  trimRight
                />
                <div className="flex gap-14 mt-4">
                  <div className="leading-[18.2px] text-sm font-normal whitespace-nowrap">
                    <div className="mb-2">Google ID:</div>
                    <div>Address:</div>
                  </div>
                  <div className="leading-[20.8px] text-base font-medium">
                    <div className="mb-2 whitespace-nowrap">
                      {project &&
                        styleGoogleCustomerId(project.gad_customer_id)}
                    </div>
                    <div>
                      {project &&
                        `${project.property.address}, ${project.property.city}, ${project.property.state} ${project.property.postal_code}, ${project.property.country_code}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="bg-white md:col-span-2 px-5 py-8 xs:px-8 xs:py-10">
          {loadingFlag ? (
            <>
              <div className="flex flex-wrap justify-between items-center gap-6">
                <div>
                  <div className="leading-[28.6px] text-[22px] font-bold mb-2">
                    <Skeleton
                      width={97}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <div className="leading-[20.8px] text-base font-medium">
                    <Skeleton
                      width={339}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                </div>
                <div className="grid w-full xs:w-fit xs:flex flex-wrap items-center gap-6">
                  <Skeleton
                    width={300}
                    height={46}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                    borderRadius={4}
                  />
                  <div className="grid xs:flex xs:flex-wrap items-center gap-4 xs:gap-6">
                    <Skeleton
                      containerClassName="w-full gap-2 leading-[15.4px] xs:w-[188px]"
                      height={42}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                      borderRadius={32}
                    />
                    <Skeleton
                      containerClassName="w-full gap-2 leading-[15.4px] xs:w-[132px]"
                      height={42}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                      borderRadius={32}
                    />
                  </div>
                </div>
              </div>
              <ul className="font-medium text-[14px] leading-[18.2px] flex gap-8 mt-6 border-b border-b-[#E9ECF1] overflow-auto">
                {[55, 48, 80, 65, 95].map((item, index) => (
                  <li key={index} className="pb-4 bg-transparent">
                    <Skeleton
                      width={item}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <>
              <div className="flex flex-wrap justify-between items-center gap-6">
                <div>
                  <div className="leading-[28.6px] text-[22px] font-bold mb-2">
                    Overview
                  </div>
                  <div className="leading-[20.8px] text-base font-medium">
                    Here are your statistics for{' '}
                    {period
                      .map((item) => {
                        return DateTime.fromSQL(item).toFormat('LLL d, yyyy'); // except with compared
                      })
                      .join(' ~ ')}
                  </div>
                </div>
                <div className="grid w-full xs:w-fit xs:flex flex-wrap items-center gap-6">
                  <DatePickerDropdown
                    className="w-full min-w-[300px] xs:w-fit"
                    period={period}
                    onChange={setPeriod}
                  />
                </div>
              </div>
              <ul className="font-medium text-[14px] leading-[18.2px] flex gap-8 mt-6 border-b border-b-[#E9ECF1] whitespace-nowrap overflow-auto">
                {REPORT_LIST.map((item, index) => (
                  <li
                    key={index}
                    className={`pb-4 bg-transparent cursor-pointer capitalize ${
                      location.pathname.includes(
                        '/' + item.replaceAll(' ', '-'),
                      ) && 'border-b-4 border-b-[#687BFE]'
                    }`}
                  >
                    <Link to={item.replaceAll(' ', '-') + location.search}>
                      {`${item.split('-')[0]}${index > 2 ? 's' : ''}`}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
          <Outlet context={[project && project.id, period]} />
        </div>
      </div>
    </>
  );
}
