import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import DashboardNotiComponent from '../../components/notification/DashboardNotiComponent';
import PropertyTile from '../../components/tile/PropertyTile';
import { apiAgent } from '../../utils/apicall';
import { prepareNoticesForDisplay } from '../../components/notification/notificationlistcomponent';
import DashboardNotificationSkeleton from '../../components/skeleton/DashboardNotificationSkeleton';
import { Link } from 'react-router-dom';
import PropertyTileSkeleton from '../../components/skeleton/PropertyTileSkeleton';
import Skeleton from 'react-loading-skeleton';
import { svgIcons } from '../../components/svgIcons/svgIcons';
import { useNavigate } from 'react-router-dom';
// import uxDialog from '../../utils/uxdialog';

function NoticeItems({ notices = [] }) {
  // TODO: when notices are not fetched
  // TODO: when there's no notices
  return notices.map((notice) => {
    return <DashboardNotiComponent notice={notice} />;
  });
}

function PropertyItems({ properties = [] }) {
  return properties.map((property) => {
    return (
      <div className="tile-flex-container" key={property.id}>
        <PropertyTile property={property} />
      </div>
    );
  });
}

export default function Dashboard() {
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const navigator = useNavigate();
  const [notices, setNotices] = useState([]);
  const [favorProps, setFavorProps] = useState([]);
  const [notiLoaded, setNotiLoaded] = useState(false);
  const [propLoaded, setPropLoaded] = useState(false);

  const fetchNotices = async () => {
    const dt = DateTime.now();
    const todayStart = dt
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toISO();
    try {
      setNotiLoaded(false);
      const response = await apiAgent.get({
        path: '/notices/',
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
        params: { created_at__gte: todayStart, ordering: '-created_at' },
      });
      let _notices = await response.json();
      prepareNoticesForDisplay(_notices);
      setNotices(_notices);
      setNotiLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFavorProps = async () => {
    try {
      setPropLoaded(false);
      const response = await apiAgent.get({
        path: '/properties/',
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
        params: { only_favorite: true },
      });
      const favorProps = await response.json();
      const favorPropsIds = favorProps.map((favorProp) => favorProp.id);
      const _favorProps = favorProps.map((favorProp) => {
        favorProp.isFavorite = favorPropsIds.includes(favorProp.id);
        return favorProp;
      });
      setFavorProps(_favorProps);
      setPropLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // premature
    navigator('/properties');
    // TODO: retry on fetch failures.
    fetchNotices();
    fetchFavorProps();
    // Set page title and save navigation history
    const pageTitle = 'Dashboard';
    window.document.title = `Apartment PPC Automation | Flair ${pageTitle}`;
  }, []);

  return notiLoaded && propLoaded ? (
    <div className="px-5 xs:px-10 pt-6 pb-12">
      <div className="flex flex-col gap-4 md:flex-row md:justify-between md:items-center">
        <div className="hidden xs:block w-full md:max-w-[580px] relative">
          <input
            className="flair-search"
            placeholder="Search your properties here"
          />
          <div className="absolute top-0 left-[21px] h-full flex items-center">
            <div className="w-[17px] h-[17px]">{svgIcons.search}</div>
          </div>
        </div>
        {userInfo.is_staff && (
          <Link
            to="/properties/add"
            className="flair-btn flair-btn-md flair-btn-primary w-[143px] min-w-[143px] max-w-[143px] mr-0 ml-auto"
          >
            <span className="w-[13px]">{svgIcons.list_add}</span>Add property
          </Link>
        )}
      </div>
      <div className="grid gap-4 mt-4 xs:mt-10">
        <h5>Welcome back!</h5>
        <p>
          Here you'll find high-level updates and action items on your property
          campaigns
        </p>
      </div>
      <div className="mt-6 pt-6 border-t border-[#6F728B21] grid gap-y-[20px]">
        <div className="font-medium text-[14px] leading-[18.2px]">
          TODAY'S NOTIFICATIONS
        </div>
        {notices.length === 0 ? (
          <div className="bg-white p-4 670w:p-8 text-center">
            <div className="w-[50px] h-[50px] text-[#B0C5DE] bg-[#B0C5DE]/[.15] rounded-full flex justify-center items-center mx-auto">
              <div className="w-[18px] h-[21.5px]">{svgIcons.notification}</div>
            </div>
            <div className="font-bold text-4 leading-[20.8px] mt-[20px] mb-2">
              No new notifications
            </div>
            <p>
              You don't have notifications yet. When they appear - you'll be
              notified
            </p>
          </div>
        ) : (
          <div className="h-52 overflow-auto grid gap-2">
            <NoticeItems notices={notices} />
          </div>
        )}
      </div>
      <div className="mt-[28px] pt-6 border-t border-[#6F728B21] grid gap-y-[20px]">
        <div className="font-medium text-[14px] leading-[18.2px]">
          MY FAVORITES
        </div>
        {favorProps.length === 0 ? (
          <div className="bg-white p-8 670w:p-16 text-center">
            <div className="w-[50px] h-[50px] text-[#B0C5DE] bg-[#B0C5DE]/[.15] rounded-full flex justify-center items-center mx-auto">
              <div className="w-[18px] h-[21.5px]">{svgIcons.notification}</div>
            </div>
            <div className="font-bold text-4 leading-[20.8px] mt-[20px] mb-2">
              No favorite properties
            </div>
            <p>
              You don't have favorite properties yet. Mark the property and it
              will appear here.
            </p>
          </div>
        ) : (
          <div className="property-tiles-container">
            <PropertyItems properties={favorProps} />
          </div>
        )}
      </div>
    </div>
  ) : (
    // skeleton
    <div className="px-5 xs:px-10 pt-6 pb-12">
      <div className="flex flex-col gap-4 md:flex-row md:justify-between md:items-center">
        <div className="hidden xs:block w-full md:max-w-[580px]">
          <Skeleton
            height={46}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
        {userInfo && userInfo.is_staff && (
          <Skeleton
            borderRadius={32}
            width={143}
            height={42}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        )}
      </div>
      <div className="grid gap-4 mt-4 xs:mt-10">
        <h5>
          <Skeleton
            width={261}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </h5>
        <p>
          <Skeleton
            width={563}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </p>
      </div>
      <div className="mt-6 pt-6 border-t border-[#6F728B21] grid gap-y-[20px]">
        <div className="font-medium text-[14px] leading-[18.2px]">
          <Skeleton
            width={182}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
        <div className="grid gap-2">
          <DashboardNotificationSkeleton />
        </div>
      </div>
      <div className="mt-[28px] pt-6 border-t border-[#6F728B21] grid gap-y-[20px]">
        <div className="font-medium text-[14px] leading-[18.2px]">
          <Skeleton
            width={100}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
        <div className="property-tiles-container">
          <PropertyTileSkeleton />
        </div>
      </div>
    </div>
  );
}
