export const ProjectStatus = {
  ACTIVE: '7ACTV',
};

export const BudgetRecommendationStatus = {
  SUGGESTED: '1NONE',
  DECLINED: '2DECL',
  ACCEPTED: '3ACCT',
};

export const PhotoCategoryList = {
  'M-L': 'Market Image',
  'M-S': 'Square Market Image',
  'L-L': 'Logo Image',
  'L-S': 'Square Logo Image',
  '*-*': 'Draft',
};

export const noticeTypeLabels = {
  company: ['company/create', 'company/edit'],
  user: ['user/create', 'user/edit/password', 'user/edit/user_info'],

  budgetrecommendation: ['budgetrecommendation/create'],
  property: ['property/create', 'property/edit'],
  project: [
    'project/create',
    'project/enable',
    'project/activate',
    'project/pause',
    'project/edit/budget',
  ],
  stats: [
    'stats/clicks/out_of_range/less_than',
    'stats/impressions/out_of_range/less_than',
    'stats/conversion/out_of_range/less_than',
    'stats/conversionrate/out_of_range/less_than',
    'stats/cpc/out_of_range/less_than',
    'stats/adspend/out_of_range/less_than',
    'stats/clicks/out_of_range/more_than',
    'stats/impressions/out_of_range/more_than',
    'stats/conversion/out_of_range/more_than',
    'stats/conversionrate/out_of_range/more_than',
    'stats/cpc/out_of_range/more_than',
    'stats/adspend/out_of_range/more_than',
  ],
  report: ['report/create'],
};

export const noticeTypeColors = {
  'company/create': '#38D086',
  'company/edit': '#38D086',
  'user/create': '#38D086',
  'user/edit/password': '#F6D214',
  'user/edit/user_info': '#38D086',
  'property/create': '#38D086',
  'property/edit': '#38D086',
  'project/create': '#38D086',
  'project/enable': 'orange',
  'project/activate': '#38D086',
  'project/pause': '#F6D214',
  'project/edit/budget': '#EE380D',
  'stats/clicks/out_of_range/less_than': '#e066ff',
  'stats/impressions/out_of_range/less_than': '#e066ff',
  'stats/conversion/out_of_range/less_than': '#e066ff',
  'stats/conversionrate/out_of_range/less_than': '#e066ff',
  'stats/cpc/out_of_range/less_than': '#e066ff',
  'stats/adspend/out_of_range/less_than': '#e066ff',
  'stats/clicks/out_of_range/more_than': '#a300cc',
  'stats/impressions/out_of_range/more_than': '#a300cc',
  'stats/conversion/out_of_range/more_than': '#a300cc',
  'stats/conversionrate/out_of_range/more_than': '#a300cc',
  'stats/cpc/out_of_range/more_than': '#a300cc',
  'stats/adspend/out_of_range/more_than': '#a300cc',
};

export const TimeZones = [
  'Pacific/Midway',
  'Pacific/Wake',
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Metlakatla',
  'America/Adak',
  'Pacific/Honolulu',
  'America/St_Thomas',
];

export const StateNames = {
  US: 'USA',
  CA: 'Canada',
};

export const ProvinceNames = {
  US: [
    'Alaska',
    'Alabama',
    'Arkansas',
    'Arizona',
    'California',
    'Colorado',
    'Connecticut',
    'District of Columbia',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Iowa',
    'Idaho',
    'Illinois',
    'Indiana',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Massachusetts',
    'Maryland',
    'Maine',
    'Michigan',
    'Minnesota',
    'Missouri',
    'Mississippi',
    'Montana',
    'North Carolina',
    'North Dakota',
    'Nebraska',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'Nevada',
    'New York',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Virginia',
    'Vermont',
    'Washington',
    'Wisconsin',
    'West Virginia',
    'Wyoming',
  ],
  CA: [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Nova Scotia',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
  ],
};

// 'Pacific/Midway': ['UTC-11:00', 'UTC-11:00'],
// 'Pacific/Wake': ['UTC+12:00', 'UTC+12:00'],
// 'America/New York': ['UTC-5:00', 'UTC-4:00'],
// 'America/Chicago': ['UTC-6:00', 'UTC-5:00'],
// 'America/Denver': ['UTC-7:00', 'UTC-6:00'],
// 'America/Phoenix': ['UTC-7:00', 'UTC-7:00'],
// 'America/Los Angeles': ['UTC-8:00', 'UTC-7:00'],
// 'America/Anchorage': ['UTC-9:00', 'UTC-8:00'],
// 'America/Metlakatla': ['UTC-8:00', 'UTC-8:00'],
// 'America/Adak': ['UTC-10:00', 'UTC-9:00'],
// 'Pacific/Honolulu': ['UTC-10:00', 'UTC-10:00'],
// 'America/St.Thomas': ['UTC-4:00', 'UTC-4:00'],

export const REPORT_LIST = [
  'account-overview',
  'ad group ad-overview',
  'bidding strategy-overview',
  'keyword-performance',
  'search term-overview',
  'DSA search term-overview',
];
